import React from 'react';
import MyMenu from './Menu';

class Burger extends React.Component {
    render() {
        return (
            <section className="burgerMenu">
                <MyMenu />
            </section>
        );
    }
};

export default Burger;
