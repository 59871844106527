import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '..';
import cl_Address from '../components/Address';
import AddressForm from '../components/AddressForm';
import LocationForm from '../components/LocationForm';
import Offer from '../components/Offer';
import PageName from '../components/PageName';
import BasePage from '../components/basePage';
import { ACTIVATE_ROUTE, ADMIN_ROUTE } from '../env';

const RegisterForm = observer( () => {
    const {user} = useContext(Context);
    const [addrPopup, setAddrPopup] = useState(false);
    const [locPopup, setLocPopup] = useState(false);

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [location, setLocation] = useState('');
    const [ownerTel, setOwnerTel] = useState('');
    const [info, setInfo] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [promo, setPromo] = useState('');

    const Address = new cl_Address();
    const [addrJSON, setAddrJSON] = useState(Address.getObj());
    const navigate = useNavigate();

    const el_click = (e) => {
        if(e.target.matches('.addressInfo > .contextButton')) {
            e.preventDefault();
            setAddrPopup(true);
        } else if(e.target.matches('.lakeLocation > .contextButton')) {
            e.preventDefault();
            setLocPopup(true);
        }
    };

    const test = async (e) => {
        let frame = null;
        let response = null;
        for(let i = 0; i < 100; i++) {
            frame = {
                "lakeName": `LakeName${i}`,
                "Address": Address.fromString(`Country, Area${i} обл., SubArea${i} р-н., село Locality${i}, вул. Street${i}, ${i}`),
                "Coordinates": `45.${i}, 29.${i}`,
                "ownerTel": `0671112${i}`,
                "Info": `Test info text ${i}`,
                "Login": `domain${i}@zabronuj.com.ua`,
                "Pass": `${i}`,
                "Promo": '',
            };
            console.log('frame -', frame);
            response = await user.register(frame);
            if(response.Status !== 'Success') console.log('!error! -', response);
        }
        console.log('Test Done!');
    };

    const el_submit = async (e) => {
        e.preventDefault();
        let frame = {
            "lakeName": name,
            "Address": addrJSON,
            "Coordinates": location,
            "ownerTel": ownerTel,
            "Info": info,
            "Login": email,
            "Pass": pass,
            "Promo": promo,
        };
        let response;
        if(user.user.lakeName === 'Supercgenybr') {
            response = await user.send(
                'cl_SuperAdmin',
                '/api/addLake',
                frame
            );
        } else {
            response = await user.register(frame);
            if (response.Status !== 'Success') return response;
            if (response.Data.user.lakeName === 'Supercgenybr')
                navigate(ADMIN_ROUTE, { replace: false });
            else
                navigate(ACTIVATE_ROUTE, { replace: false });
        }
        if (response.Status !== 'Success') return response;
        alert("Дані збережено!");
    };

    return (
        <>
            {user.user.lakeName !== 'Supercgenybr' && <PageName name='Реєстрація водойми'/>}
            <form className="pageBlock formRegister" action="#" method="post"
                onClick={el_click} onSubmit={el_submit}
            >
                <legend className="fieldname">Загальні дані:</legend>
                <div className='halfBlockContainer'>
                    <div className="fieldbase mainInfo halfBlock">
                        <input className="fielddata inputText" type="text" name="Name" maxLength="30" placeholder="< Назва водойми >" required
                            value={name} onChange={e => {
                                setName(e.target.value);
                            }} />
                        <div className="addressInfo fieldbase fielddata inputText advanceField lakeAddress">
                            <input className="inputText" type="text" name="Address" maxLength="30" placeholder="< Адреса водойми >" disabled required
                                defaultValue={address}/>
                            <button className="button contextButton" type="button">...</button>
                        </div>
                        <div className="fieldbase fielddata inputText advanceField lakeLocation">
                            <input className="inputText" type="text" name="Coordinates" maxLength="30" placeholder="< Координати >" disabled required
                                defaultValue={location}/>
                            <button className="button contextButton" type="button">...</button>
                        </div>
                        <input className="fielddata inputText" type="tel" name="ownerTel" maxLength="13" placeholder="< Телефон власника >" required
                            value={ownerTel} onChange={e => {
                                setOwnerTel(e.target.value);
                            }}/>
                        <textarea className="fielddata inputText textArea" name="description" placeholder="< Загальний опис >" rows="5" required
                            value={info} onChange={e => {
                                setInfo(e.target.value);
                            }}/>
                        <input className="fielddata inputText" type="email" id="email" maxLength="100" placeholder="< Login / Email >" required
                            value={email} onChange={e => {
                                setEmail(e.target.value);
                            }}/>
                    </div>
                    <div className="fieldbase commerseData halfBlock">
                    </div>
                </div>
                <div className="fieldbase subInfo">
                    <input className="fielddata inputText" type="password" id="password" maxLength="100" placeholder="< Password >" autoComplete="off" required
                        value={pass} onChange={e => {
                            setPass(e.target.value);
                        }}/>
                    <input className="fielddata inputText" type="text" name="promocode" maxLength="30" placeholder="< Промо-код >"
                        value={promo} onChange={e => {
                            setPromo(e.target.value);
                        }}/>
                </div>
                <button className="button buttonSubmit" type="submit">Зареєструватися</button>
            </form>
            {user.user.lakeName !== 'Supercgenybr' && <Offer />}
            {addrPopup &&
                <AddressForm obj={addrJSON} onClick={(e) => {
                    setAddrPopup(false);
                }}
                onChange={(string) => {
                    setAddress(string);
                    setAddrJSON(Address.fromString(string));
                }} />
            }
            {locPopup &&
                <LocationForm obj={location} onClick={(e) => {
                    setLocPopup(false);
                }}
                onChange={(string) => {
                    setLocation(string);
                }} />
            }
        </>
    );
});

const Register = observer( () => {

    useEffect(() => {
        document.querySelector('meta[name="og:description"]').setAttribute(
            'content',
            'Реєстрація водойми | Створення аккаунту водойми'
        );
        document.querySelector('title[id="mainTitle"]').textContent =
            'Реєстрація водойми | Онлайн-сервіс Забронюй!';
    }, [])

    return (
        <BasePage>
            <RegisterForm/>
        </BasePage>
    );
});

export default Register;
export { RegisterForm };

