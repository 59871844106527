import { observer } from "mobx-react-lite";
import React from "react";
import MyMenu from "./Menu";

const Header = () => {
    const burgerClick = (e) => {
        let burger = document.querySelector('.burger');
        let bMenu = document.querySelector('.burgerMenu');
        // let body = document.querySelector('body');
        // body.classList.toggle('lock');
        burger.classList.toggle('active');
        bMenu.classList.toggle('active');
    }

    return (
        <header id="page_header" className="page_header" style={{
            backgroundImage: `url(${process.env.REACT_APP_API_URL}/test_picture1.jpg)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}>
            {/* <h1>Риболовля</h1> */}
            <img className="mainBanner" src={`${process.env.REACT_APP_API_URL}/Banner.png`} ></img>
            <div className="burger" onClick={burgerClick}>
                <span></span>
            </div>
            <MyMenu />
        </header>
    );
};

export default Header;
