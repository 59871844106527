
export default class cl_Address {

    constructor() {
        this.Country = this.Area = this.SubArea = this.LocType = this.Locality = this.Street = this.Building = "";
        this.addrData = {};
    }

    toString() {
        let address = "";
        if(this.Country)
            address = this.Country + ", ";
        if(this.Area)
            address += this.Area + " обл.";
        if(this.SubArea)
            address += ", " + this.SubArea + " р-н.";
        if(this.LocType)
            address += ", " + this.LocType;
        if(this.Locality)
            address += " " + this.Locality;
        if(this.Street)
            address += ", вул. " + this.Street;
        if(this.Building)
            address += ", " + this.Building;
        return address;
    }

    fromString(string) {
        let res = string.split(", ");
        for(let i = 0; i < res.length && res[i]; i++) {
            switch(i) {
                case 0:
                    this.Country = res[i];
                break;
                default:
                    if(res[i].includes(" обл.")) {
                        this.Area = res[i].split(" обл.")[0];
                    } else if(res[i].includes(" р-н.")) {
                        this.SubArea = res[i].split(" р-н.")[0];
                    } else if(res[i].includes("місто ") ||
                            res[i].includes("село ") ||
                            res[i].includes("смт ") ||
                            res[i].includes("селище ")) {
                        this.LocType = res[i].split(" ")[0];
                        this.Locality = res[i].split(this.LocType+" ")[1];
                    } else if(res[i].includes("вул. ")) {
                        this.Street = res[i].split("вул. ")[1];
                        i++;
                        this.Building = res[i];
                    }
                break;
            }
        }
        return this.getObj();
    }

    fromJSON(json) {
        this.Country = json.Country;
        this.Area = json.Area;
        this.SubArea = json.SubArea;
        this.LocType = json.LocType;
        this.Locality = json.Locality;
        this.Street = json.Street;
        this.Building = json.Building;
        return this.getObj();
    }

    getObj() {
        return {
            'Country': this.Country,
            'Area': this.Area,
            'SubArea': this.SubArea,
            'LocType': this.LocType,
            'Locality': this.Locality,
            'Street': this.Street,
            'Building': this.Building,
        };
    }

    async init(user, json) {
        if(json) this.fromJSON(json);
        this.addrData = await user.requestPrereq(`addresses.json`, true);
        if(!this.addrData)
            return {Status:'PREREQUISITES_ERROR'};
        return {Status:'Success'};
    }
}
