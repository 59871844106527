import { makeAutoObservable } from "mobx";
import { arrayFindItem } from "../components/common";
import { $authHost, $host } from "../http";

export default class userAPI {
    constructor() {
        this._isAuth = false;
        this._user = {};
        makeAutoObservable(this);
    }

    setIsAuth(val) {
        this._isAuth = val;
    }

    setUser(val) {
        this._user = val;
    }

    get isAuth() {
        return this._isAuth;
    }

    get user() {
        return this._user;
    }

    async register(frame) {
        try {
            let response = await this.send('cl_Register', '/api/register', frame);
            if(response.Status !== 'Success') {
                return response;
            }
            this.setUser(response.Data.user);
            this.setIsAuth(true);
            localStorage.setItem('accessToken', response.Data.accessToken);
            return response;
        } catch (err) {
            alert(err.response.data.message);
        }
    }
    
    async login(login, pass) {
        try {
            let frame = {
                Class: {},
                Data: {
                    'Login':login,
                    'Pass':pass
                }
            };
            
            const {data} = await $host.post('/api/login', frame);
            if(data.Status !== 'Success') {
                this.checkStatus(data.Status);
                return data;
            }
            this.setUser(data.Data.user);
            this.setIsAuth(true);
            localStorage.setItem('accessToken', data.Data.accessToken);
            return data;
        } catch (err) {
            alert(err.response.data.message);
        }
    }
    
    async logout() {
        try {
            const {data} = await $authHost.post('/api/logout');
            this.setUser({});
            this.setIsAuth(false);
            localStorage.removeItem('accessToken');
            return data;
        } catch (err) {
            alert(err.response.data.message);
        }
    }
    
    async checkAuth() {
        try {
            const {data} = await $host.get('/api/refresh');
            if(data.Status !== 'Success') {
                this.setUser({});
                this.setIsAuth(false);
                localStorage.removeItem('accessToken');
                return data;
            }
            this.setUser(data.Data.user);
            this.setIsAuth(true);
            localStorage.setItem('accessToken', data.Data.accessToken);
            return data;
        } catch (err) {
            this.setUser({});
            this.setIsAuth(false);
            localStorage.removeItem('accessToken');
            alert(err);
        }
    }

    async checkRefresh(request) {
        if(!request) return {Status:'ERRPARAM'};
        let {data} = await request();
        if(data.Status === 'NOT_AUTHORIZED') {
            let refresh = await this.checkAuth();
            if(refresh.Status !== 'Success') return refresh;
            if(refresh.Data.accessToken)
                return (await request()).data;
        }
        return data;
    }

    async send(src, cmd, payload) {
        try {
            let frame = {
                "Class": src,
                "Data": payload,
            };
            let inst = $host;
            if(this._isAuth) {
                inst = $authHost;
            }
            const request = async () => {return await inst.post(cmd, frame);};
            let response = await this.checkRefresh(request);
            if(response.Status !== 'Success') {
                this.checkStatus(response.Status);
            }
            return response;
        } catch (err) {
            alert(err);
        }
    }

    async sendFile(file) {
        try {
            const request = async () => {return await $authHost.post('api/uploadFile', file);};
            let response = await this.checkRefresh(request);
            if(response.Status !== 'Success') {
                this.checkStatus(response.Status);
            }
            return response;
        } catch (err) {
            alert(err);
        }
    }

    async requestPrereq(src, stat = false) {
        if(!src) return null;
        let inst = $host;
        if(this._isAuth) {
            inst = $authHost;
        }
        const request = async () => {return await inst.get(`/${src}`);};
        let response = await this.checkRefresh(request);
        if(response.Status !== 'Success') {
            this.checkStatus(response.Status);
        }
        if(stat) return response;
        return response.Data;
    }

    setUserID(id) {
        localStorage.setItem('UserID', id);
    }

    getUserID() {
        return localStorage.getItem('UserID');
    }

    checkUserID(id) {
        if(!id) return false;
        let str = localStorage.getItem('UserID');
        if(id === str) return true;
        return false;
    }

    removeUserID() {
        localStorage.removeItem('UserID');
    }

    addUserID(id) {
        let str = localStorage.getItem('UserID');
        let array = [];
        if(this._isAuth) return false;
        if(str) {
            array = str.split(',');
            let item = arrayFindItem(array, id);
            if(item) return false;
        }
        this.setUserID([...array, id]);
        return true;
    }

    checkStatus(status) {
        switch(status) {
            case 'NAME_ALREADY_PRESENT':
                alert('Таке ім`я вже присутнє! Оберіть, будь ласка, інше.');
                break;
            case 'EMPTY_NAME':
                alert('Поле назви порожнє!');
                break;
            case 'StartDateTimeLowerThanPresent':
                alert('Початкові дата/час менші, ніж поточні!');
                break;
            case 'EndDateTimeLowerThanPresentOrEqualTo':
                alert('Кінцеві дата/час менші, ніж поточні!');
                break;
            case 'MaxPeriodExceeded':
                alert('Максимальний період перевищено!');
                break;
            case 'ConflictInTimePeriod':
                alert('Конфлікт у часовому періоді!');
                break;
            case 'NOT_APPROVED':
                if(this.isAuth)
                    alert('Аккаунт заблоковано!');
                break;
            case 'USERPRESENT':
                alert('Такий користувач вже існує!');
                break;
            case 'NOT_AUTHORIZED':
                alert('Помилка авторизації!');
                break;
            case 'ERRPARAM':
                alert('Помилка у параметрах!');
                break;
            case 'INCORRECT_PASSWORD':
                alert('Неправильний пароль!');
                break;
            case 'INCORRECT_USER_ID':
                alert('Некоректний ID користувача!');
                break;
            case 'USER_NOT_FOUND':
                alert('Користувач з таким email не знайдений!');
                break;
            case 'FAILED':
                alert('Помилка операції!');
                break;
            case 'ORDER_NOT_FOUND':
                alert('Запис резерву не знайдено!');
                break;
            case 'MISSING_REQUEST_DATA':
                alert('Бракує даних у запиті!');
                break;
            case 'REQUESTED_TIME_BUSY':
                alert('Вказаний час вже зайнятий!');
                break;
            case 'REMOVE_FAILED':
                alert('Помилка видалення!');
                break;
            case 'EMPTY_TOKEN':
                alert('Порожній токен!');
                break;
            case 'DB_ENTRY_NOT_FOUND':
                alert('Елемент бази даних не знайдено!');
                break;
            default:
                break;
        }
    }

    async apply(cl_name, sublake, cmd, payload) {
        let frame;
        if(sublake) {
            frame = {
                "Sublake": sublake,
                "Data": payload,
            };
        } else {
            frame = payload;
        }
        return await this.send(cl_name, cmd, frame);
    }

}
