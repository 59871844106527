import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Context } from "..";
import DynamicList from "./DynamicList";
import Popup from "./Popup";
import { dateOffsetRemove, dayToDate } from "./common";

const OrderForm = observer( ({day, sublakes, type, sublake, place, obj, onClick, onChange}) => {
    const {user} = useContext(Context);
    const [vName, setVName] = useState('');
    const [vSecondName, setVSecondName] = useState('');
    const [vThirdName, setVThirdName] = useState('');
    const [vAutoLicense, setVAutoLicense] = useState('');
    const [vTel, setVTel] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [ar_Services, setServices] = useState([]);
    const [_subLake, setSubLake] = useState(sublake);
    const [_place, setPlace] = useState(place);
    const [searchParams] = useSearchParams();
    const [hours, setHours] = useState(1);
    const [days, setDays] = useState(1);
    const [allServices, setAllServices] = useState([]);

    useEffect(() => {
        initStartDate();
        init();
    }, []);

    useEffect(() => {
        updateQuantity();
    }, [startDate, endDate]);

    const init = async () => {
        if (obj) {
            let result = await user.send(
                'cl_OrderForm',
                `${!searchParams.has('name') ? '/api' : ''}/getOrder${searchParams.has('name') ? '?' + searchParams : ''}`,
                { 'Day': day, 'vID':obj.vID, 'Sublake': sublake, 'PlaceNum': place }
            );
            if (result.Status !== 'Success') return result;
            let Data = result.Data;
            setVName(Data.vName);
            setVSecondName(Data.vSecondName);
            setVThirdName(Data.vThirdName);
            setVAutoLicense(Data.vAutoLicense);
            setVTel(Data.vTel);
            setStartDate(Data.startDate);
            setEndDate(Data.endDate);
            setServices([...Data.services]);
            setHours(Data.hours);
            setDays(Data.days);
        }
        let result = await user.requestPrereq(
            `${!searchParams.has('name') ? 'api/' : ''}getServices${searchParams.has('name') ? '?' + searchParams : ''}`
        );
        if (!result)
            return { Status: 'PREREQUISITES_ERROR' };
        if (result.length > 0) {
            setAllServices(result);
        }
    }

    const initStartDate = (clear = false) => {
        if(!clear && startDate !== '') return;
        let now = new Date(dayToDate(day));
        let offset = now.getTimezoneOffset();
        now = now - ((offset - 5) * 60 * 1000);
        let date = new Date(now);
        date = date.toISOString();
        date = date.split(':');
        date.splice(2, 1);
        date = date.join(':');
        setStartDate(date);
    };

    const updateQuantity = () => {
        let start = new Date(startDate);
        let end = new Date(endDate);
        let period = end - start;
        let tminutes = period / 1000 / 60;
        let thours = period / 1000 / 60 / 60;
        let hrs = Math.trunc(thours);
        if(tminutes % 60) hrs += 1;
        hrs = hrs ? hrs : 1;
        setHours(hrs);
        let ds = Math.trunc(thours / 24);
        if(thours % 24) ds += 1;
        ds = ds ? ds : 1;
        setDays(ds);
    }

    const asyncTest = (e) => {
        let frame = null;
        let Day = null;
        let now = new Date();
        for (let i = 0; i < 30; i++) {    //Packets
            Day = new Date();
            Day.setTime(Day.getTime() + 5*60*1000);
            now.setDate(Day.getDate() + 1);
            frame = {
                'Day': 0,
                'Sublake': '',
                'PlaceNum': i + 1,
                'Status': 'Reserved',
                'startDate': dateOffsetRemove(Day),
                'endDate': dateOffsetRemove(now),
                'vName': `VisitorName${i}`,
                'vSecondName': `VisitorSecName${i}`,
                'vThirdName': `VisitorThirName${i}`,
                'vAutoLicense': `AutoLicense${i}`,
                'vTel': `06711122${i}`,
                'services': [
                    { "Name": "Long Service Name 0", "Price": 1, "Quantity": 1, "quanType": "Разова", "Default": false },
                    { "Name": "Long Service Name 1", "Price": 2, "Quantity": 1, "quanType": "Разова", "Default": false },
                    { "Name": "Long Service Name 2", "Price": 3, "Quantity": 1, "quanType": "Разова", "Default": false },
                    { "Name": "Long Service Name 3", "Price": 4, "Quantity": 1, "quanType": "Разова", "Default": false },
                    { "Name": "Long Service Name 4", "Price": 5, "Quantity": 1, "quanType": "Разова", "Default": false },
                    { "Name": "Long Service Name 5", "Price": 6, "Quantity": 1, "quanType": "Разова", "Default": false },
                    { "Name": "Long Service Name 6", "Price": 7, "Quantity": 1, "quanType": "Разова", "Default": false }
                ],
                'hours': 24,
                'days': 1,
                'uID': user.getUserID(),
            };
            console.log('frame -', frame);
            let response = user.send(
                'cl_OrderForm',
                `/orderPlace?name=LakeName0`,
                frame
            );
            // if (response.Status !== 'Success') console.log('!error! -', response);
        }
    };

    const test = async (e) => {
        let frame = null;
        let response = null;
        let Day = null;
        let now = new Date();
        for(let i = 0; i < 100; i++) {    //Lake
            for (let j = 0; j < 7; j++) {   //Day
                Day = new Date();
                Day.setDate(Day.getDate() + j);
                Day.setTime(Day.getTime() + 5*60*1000);
                now.setDate(Day.getDate() + 1);
                for (let k = 0; k < 30; k++) {   //Place
                    frame = {
                        'Day': j,
                        'Sublake': '',
                        'PlaceNum': k+1,
                        'Status': 'Reserved',
                        'startDate': dateOffsetRemove(Day),
                        'endDate': dateOffsetRemove(now),
                        'vName': `VisitorName${k}`,
                        'vSecondName': `VisitorSecName${k}`,
                        'vThirdName': `VisitorThirName${k}`,
                        'vAutoLicense': `AutoLicense${k}`,
                        'vTel': `06711122${k}`,
                        'services': [
                            {"Name":"Long Service Name 0","Price":1,"Quantity":1,"quanType":"Разова","Default":false},
                            {"Name":"Long Service Name 1","Price":2,"Quantity":1,"quanType":"Разова","Default":false},
                            {"Name":"Long Service Name 2","Price":3,"Quantity":1,"quanType":"Разова","Default":false},
                            {"Name":"Long Service Name 3","Price":4,"Quantity":1,"quanType":"Разова","Default":false},
                            {"Name":"Long Service Name 4","Price":5,"Quantity":1,"quanType":"Разова","Default":false},
                            {"Name":"Long Service Name 5","Price":6,"Quantity":1,"quanType":"Разова","Default":false},
                            {"Name":"Long Service Name 6","Price":7,"Quantity":1,"quanType":"Разова","Default":false}
                        ],
                        'hours': 24,
                        'days': 1,
                        'uID': user.getUserID(),
                    };
                    console.log('frame -', frame);
                    response = await user.send(
                        'cl_OrderForm',
                        `/orderPlace?name=LakeName${i}`,
                        frame
                    );
                    if (response.Status !== 'Success') console.log('!error! -', response);
                    // if (response.Data) user.setUserID(response.Data);
                }
            }
        }
        console.log('Test Done!');
    };

    const apply = async () => {
        let frame = {
            'Day': day,
            'Sublake':_subLake,
            'PlaceNum':_place,
            'Status':'Reserved',
            'startDate':startDate,
            'endDate':endDate,
            'vName':vName,
            'vSecondName':vSecondName,
            'vThirdName':vThirdName,
            'vAutoLicense':vAutoLicense,
            'vTel':vTel,
            'services':ar_Services,
            'hours': hours,
            'days': days,
            'uID': user.getUserID(),
        };
        let response = await user.send(
            'cl_OrderForm',
            `${!searchParams.has('name')?'/api':''}/orderPlace${searchParams.has('name')?'?'+searchParams:''}`,
            frame
        );
        if(response.Status !== 'Success')
            return false;
        if(response.Data)
            user.setUserID(response.Data);
        return true;
    };

    const orderReject = async () => {
        let start = new Date(startDate);
        let now = new Date();
        let frame = null;
        if(now > start) {
            frame = {
                'Type': 'ruleViolation',
                'order': obj,
                'violatedRules': [],
            };
        }
        else
            frame = {
                'Type': 'cancel',
                'order': obj,
                'initiator': 'lake',
            };
        let response = await user.send(
            'cl_OrderForm',
            '/api/orderReject',
            frame
        );
        if(response.Status !== 'Success') return false;
        alert('Запис видалено!');
        return true;
    };

    return (
        <Popup open={true} name="cl_OrderForm" onClick={(e) => onClick(e)}>
            <form className="reservForm popup" action="#" id="reservForm"
                onSubmit={async e => {
                    e.preventDefault();
                    if(await apply()) onChange(e);
                }}
            >
                <header>
                    {type === 'fisherAdd'
                    ?   <h3>Забронювати місце</h3>
                    :   <h3>Дані відвідувача</h3>
                    }
                </header>
                <div className="fieldbase fisherInfoBase">
                    <div className="fieldbase fisherData">
                        <input className="fielddata inputText" type="text" name="vName" maxLength="30" placeholder="< Ім'я >" required
                        value={vName} onChange={e => {
                            setVName(e.target.value);
                        }} />
                        <input className="fielddata inputText" type="text" name="vSecondName" maxLength="30" placeholder="< Прізвище >"
                        value={vSecondName} onChange={e => {
                            setVSecondName(e.target.value);
                        }} />
                        <input className="fielddata inputText" type="text" name="vThirdName" maxLength="30" placeholder="< По-батькові >"
                        value={vThirdName} onChange={e => {
                            setVThirdName(e.target.value);
                        }} />
                        <input className="fielddata inputText" type="text" name="vAutoLicense" maxLength="30" placeholder="< Номер авто >"
                        value={vAutoLicense} onChange={e => {
                            setVAutoLicense(e.target.value);
                        }} />
                        <input className="fielddata inputText" type="text" name="vTel" maxLength="30" placeholder="< Телефон >" required
                        value={vTel} onChange={e => {
                            setVTel(e.target.value);
                        }} />
                    </div>
                    <div className="fieldbase fisherIdBase">
                    </div>
                </div>
                <div className="fieldbase">
                    <select className="fielddata select" name="subLake" required value={_subLake} onChange={e => {
                        setSubLake(e.target.value);
                    }}>
                        <option className="item" value={"< Водойма >"}>
                            {"< Водойма >"}
                        </option>
                        {sublakes && sublakes.map(item =>
                            <option key={item.Name} className="item" value={item.Name}>
                                {item.Name}
                            </option>
                        )}
                    </select>
                    <select className="fielddata select" name="placeNum" required value={_place} onChange={e => {
                        setPlace(e.target.value);
                    }}>
                        <option className="item" value={"< Місце >"}>
                            {"< Місце >"}
                        </option>
                        {sublakes && sublakes.map(item => {
                            if(item.Name === _subLake) {
                                return item.places && item.places.map(pItem => {
                                        if(pItem.Status !== 'Disabled')
                                            return <option key={pItem.Num} className="item" value={pItem.Num}>
                                                {pItem.Num}
                                            </option>
                                        else return null;
                                    }
                                )
                            }
                            return null;
                        })}
                    </select>
                </div>
                <div className="fieldbase fielddata timePeriod">
                    <input className="dateTime startDate" type="datetime-local" required
                    value={startDate} onChange={e => {
                        setStartDate(e.target.value);
                    }} />
                    <span className="fielddata hyphen">-</span>
                    <input className="dateTime endDate" type="datetime-local" required
                    value={endDate} onChange={e => {
                        setEndDate(e.target.value);
                    }} />
                </div>
                <DynamicList name="services" type="order" src={allServices} obj={ar_Services} days={days} hours={hours} onChange={(newArray) => {
                    setServices([...newArray]);
                }}>
                </DynamicList>
                <div className="fieldbase buttonsArea">
                    <button className='button' type="reset"
                        onClick={e => {
                            setVName('');
                            setVSecondName('');
                            setVThirdName('');
                            setVAutoLicense('');
                            setVTel('');
                            initStartDate(true);
                            setEndDate('');
                            let defArray = [];
                            ar_Services.forEach(service => {
                                if(service.Default)
                                    defArray.push(service);
                            });
                            setServices([...defArray]);
                        }}
                    >Очистити</button>
                    {type === 'fisherInfo' &&
                        <button className='button orderReject' type="button" onClick={async e => {
                            if(await orderReject()) onChange(e);
                        }}>Вилучити</button>
                    }
                    <button className='button' type="submit">Підтвердити</button>
                </div>
            </form>
        </Popup>
    );
});

export default OrderForm;