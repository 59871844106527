import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Context } from "..";
import { arrayFindItem, arrayFindItemSmart } from "./common";
import Services from "./Services";
import UserInput from "./UserInput";

const DynamicList = observer( (props) => {
    const {user} = useContext(Context);
    const [uInput, setUInput] = useState({
        show:false,
        type:'',
    });

    const removeItem = async (e) => {
        let row = e.target.closest('.advanceField');
        if(!row) return null;
        let array = [...props.obj], value;
        if(row.classList.contains('photo')) {
            value = (row.style.backgroundImage.split('"')[1]).split('/')[3];
            let response = await user.send('cl_RawData', 'api/removeFile', value);
            if(response.Status !== 'Success') return response;
        } else if(row.classList.contains('fish')) {
            value = row.textContent.split('-',1)[0];
        } else if(row.classList.contains('catchType')) {
            value = row.textContent.split('-',1)[0];
        } else if(row.classList.contains('rule')) {
            value = row.textContent.split('-',1)[0];
        }
        let item = arrayFindItem(array, value);
        if(item) {
            array.splice(item.i, 1);
            props.onChange(array);
        }
    }

    const el_click = (e) => {
        if(e.target.matches('.contextButton.Minus')) {
            e.preventDefault();
            removeItem(e);
        } else if(e.target.matches('.newPhoto.Plus')) {
            e.preventDefault();
            setUInput({
                show:true,
                type:'inputPhoto',
            });
        } else if(e.target.matches('.lakeFishes .Plus') ||
                  e.target.matches('.lakeCatchTypes .Plus')) {
            e.preventDefault();
            setUInput({
                show:true,
                type:'inputList',
            });
        } else if(e.target.matches('.lakeRules .Plus')) {
            e.preventDefault();
            setUInput({
                show:true,
                type:'inputUserText',
            });
        }
    };

    return (
        <div>
        {props.name === 'services' ?
            <div className="fieldbase">
                <Services type={props.type} src={props.src} obj={props.obj} days={props.days} hours={props.hours} onChange={(newArray) => {
                    props.onChange(newArray);
                }} />
            </div>
            :
            <div className={`fieldbase ${props.name} dynamicList`} onClick={el_click}>
                {props.name === 'lakePhoto' && props.obj.map(item =>
                    <div key={item} className="fielddata photo advanceField" style={{
                        backgroundImage: `url(${process.env.REACT_APP_API_URL}/${item})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}>
                        <button className="button contextButton Minus">-</button>
                    </div>
                )}
                {props.name === 'lakeFishes' && props.obj.map(item =>
                    <p key={item} className="fielddata advanceField fish">
                        {item}
                        <button className="button contextButton Minus">-</button>
                    </p>
                )}
                {props.name === 'lakeCatchTypes' && props.obj.map(item =>
                    <p key={item} className="fielddata advanceField catchType">
                        {item}
                        <button className="button contextButton Minus">-</button>
                    </p>
                )}
                {props.name === 'lakeRules' && props.obj.map(item =>
                    <p key={item} className="fielddata advanceField rule">
                        {item}
                        <button className="button contextButton Minus">-</button>
                    </p>
                )}
                <button className={`fielddata ${props.buttonName || 'advanceField'} button contextButton Plus`}>+</button>
            </div>
        }
        {uInput.show
            ?   <UserInput src={props.src} obj={uInput}
                    onChange={(newItem) => {
                        if(newItem instanceof Array) {
                            let array = [...props.obj].concat(newItem);
                            props.onChange(array);
                        } else {
                            let present = arrayFindItemSmart([...props.obj], newItem);
                            if(!present) {
                                let array = [...props.obj, newItem];
                                props.onChange(array);
                                setUInput({
                                    show: false,
                                    type: '',
                                });
                            } else {
                                alert("Таке ім'я вже присутнє! Оберіть, будь ласка, інше.");
                            }
                        }
                    }}
                    onClick={(e) => {
                        setUInput({
                            show: false,
                            type: '',
                        });
                    }}
                />
            : null
        }
        </div>
    );
});

export default DynamicList;