import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import UserInput from "./UserInput";
import { addIDsToArray, findService, findServiceNDef, jsonFindItem } from "./common";

const ServiceItemInfo = observer( ({service}) => {
    return (
        <tr className="generic" id={service.Name}>
            <td className={'servicename fielddata'}>
                {service.Name}
            </td>
            <td className="hyphen">-</td>
            <td className="price">{service.Price}</td>
            <td className="hyphen">-</td>
            <td className="price servType">{service.quanType}</td>
        </tr>
    );
});

const ServiceItemOrder = observer( ({service, days, hours}) => {
    return (
        <tr className={service.Default ? 'default' : 'generic'} id={service.Name}>
            <td className={`servicename fielddata${!service.Default ? ' advanceField' : ''}`}>
                {service.Name}
                {!service.Default &&
                    <button className="button contextButton Minus">-</button>
                }
            </td>
            <td className="hyphen">-</td>
            <td className="price">{service.Price}</td>
            {service.quanType !== "Разова" &&
                <>
                    <td className="hyphen">-</td>
                    {service.quanType === "Кількісна" && 
                        <td className="price">x{service.Quantity}</td>
                    }
                    {service.quanType === "На добу" && 
                        <td className="price">x{service.Quantity} x{days ? days : 1} д</td>
                    }
                    {service.quanType === "На годину" && 
                        <td className="price">x{service.Quantity} x{hours ? hours : 1} г</td>
                    }
                </>
            }
        </tr>
    );
});

const ServiceItemConfig = observer( (props) => {
    const [price, setPrice] = useState(props.service.Price);
    const [check, setCheck] = useState(props.service.Default);
    const [quanType, setQuanType] = useState(props.service.quanType);

    return (
        <tr id={props.service.Name}>
            <td className="servicename fielddata advanceField">
                {props.service.Name}
                <button className="button contextButton Minus">-</button>
            </td>
            <td className="hyphen">-</td>
            <td className="priceEdit">
                <input className="fielddata inputText" type="text" name="price"
                    maxLength="4" placeholder="-----"
                    value={price} onChange={e => {
                        let updItem = {...props.service};
                        updItem.Price = Number(e.target.value);
                        if(!updItem.Price || updItem.Price < 0) {
                            updItem.Price = 0;
                        }
                        setPrice(updItem.Price);
                        props.onChange(updItem);
                    }}
                />
            </td>
            <td className="hyphen">-</td>
            <td className="price fielddata advanceField default">
                <input className="fielddata inputCheck" type="checkbox" name="Default"
                    checked={check} onChange={e => {
                        setCheck(e.target.checked);
                        let updItem = {...props.service};
                        updItem.Default = e.target.checked;
                        props.onChange(updItem);
                    }}
                />
            </td>
            <td className="hyphen">-</td>
            <td className="period">
                <select className="fielddata select quanType" name="period" required value={quanType} onChange={e => {
                    setQuanType(e.target.value);
                    let updItem = {...props.service};
                    updItem.quanType = e.target.value;
                    props.onChange(updItem);
                }}>
                    <option className="item" value={"Разова"}>
                        {"Разова"}
                    </option>
                    <option className="item" value={"Кількісна"}>
                        {"Кількісна"}
                    </option>
                    <option className="item" value={"На годину"}>
                        {"На годину"}
                    </option>
                    <option className="item" value={"На добу"}>
                        {"На добу"}
                    </option>
                </select>
            </td>
        </tr>
    );
});

const Services = observer( ({type, src, obj, days, hours, onChange}) => {
    const [uInput, setUInput] = useState({
        show:false,
        type:'',
    });
    const [summ, setSumm] = useState(0);

    let mark;
    if(type === 'config') {
        mark = 'servicesConfig';
    } else {
        mark = 'fieldbase';
    }

    useEffect(() => {
        init();
    }, [src]);

    useEffect(() => {
        if(type === 'order')
            getSummary();
    }, [obj, hours, days]);

    const init = async () => {
        if(type === 'order') {
            addIDsToArray(src);
            getDefault(src);
        }
    }

    const getDefault = (services) => {
        if((services instanceof Array) && services.length !== 0 && obj.length === 0) {
            let array = [];
            services.forEach(item => {
                if(item.Default) array.push(item);
            });
            addIDsToArray(array);
            onChange(array);
        }
    };

    const getSummary = () => {
        addIDsToArray(obj);
        let oldSumm = 0;
        let cntSumm = 0;
        obj.forEach(item => {
            cntSumm = item.Price * item.Quantity;
            switch(item.quanType) {
                case "На добу":
                    oldSumm += cntSumm * (days ? days : 1);
                break;
                case "На годину":
                    oldSumm += cntSumm * (hours ? hours : 1);
                break;
                default:
                    oldSumm += cntSumm;
                break;
            }
        });
        setSumm(oldSumm);
    };

    const el_click = (e) => {
        if(e.target.matches('.servicename .contextButton.Minus')) {
            e.preventDefault();
            let row = e.target.closest('tr');
            if(!row) return null;
            let array = [...obj];
            let item = null;
            switch(type) {
                case 'order':
                    item = findServiceNDef(array, row.id);
                    if(item) {
                        if(item.obj.Quantity > 1)
                            item.obj.Quantity -= 1;
                        else
                            array.splice(item.i, 1);
                        onChange(array);
                    }
                break;
                case 'config':
                    item = jsonFindItem(array, row.id);
                    if(item) {
                        array.splice(item.i, 1);
                        onChange(array);
                    }
                break;
                default:
                break;
            }
        } else if(e.target.matches('.Plus')) {
            e.preventDefault();
            let inputType = (type === 'config') ? 'inputUserText' : 'inputList';
            setUInput({
                show:true,
                type:inputType,
            });
        }
    }

    return (
        <>
            <table className={`services ${type} dynamicList ${mark}`} onClick={el_click}>
                <tbody>
                    <tr className="legend">
                        <td className="serviceslegend">
                            <legend className="fieldname tablehead">Послуги:</legend>
                        </td>
                        <td className="serviceslegend"></td>
                        <td className="serviceslegend">
                            <legend className="fieldname tablehead">Ціна(грн.):</legend>
                        </td>
                        {type === 'order' &&
                            <>
                            <td className="serviceslegend"></td>
                            <td className="serviceslegend">
                                <legend className="fieldname tablehead">Кількість:</legend>
                            </td>
                            </>
                        }
                        {type === 'config' &&
                            <>
                            <td className="serviceslegend"></td>
                            <td className="serviceslegend">
                                <legend className="fieldname tablehead">Обов'язкова:</legend>
                            </td>
                            <td className="serviceslegend"></td>
                            <td className="serviceslegend">
                                <legend className="fieldname tablehead">Тип:</legend>
                            </td>
                            </>
                        }
                        {type === 'info' &&
                            <>
                            <td className="serviceslegend"></td>
                            <td className="serviceslegend">
                                <legend className="fieldname tablehead">Тип:</legend>
                            </td>
                            </>
                        }
                    </tr>
                    {type === 'config' && obj.map(item =>
                        <ServiceItemConfig key={item.Name} service={item} onChange={(updItem) => {
                            let array = [...obj];
                            let fItem = jsonFindItem(array, updItem.Name);
                            if(fItem) {
                                fItem.obj.Price = updItem.Price;
                                fItem.obj.quanType = updItem.quanType;
                                fItem.obj.Default = updItem.Default;
                                onChange(array);
                            }
                        }} />
                    )}
                    {type === 'order' && obj && obj.map(item =>
                        <ServiceItemOrder key={item.id} service={item} quantity={item.Quantity} days={days} hours={hours} />
                    )}
                    {type === 'info' && obj && obj.map(item =>
                        <ServiceItemInfo key={item.Name} service={item} />
                    )}
                    {type !== 'info' &&
                        <tr className="new">
                            <td className="servicename fielddata advanceField newItem">
                                <button className="button contextButton Plus">+</button>
                            </td>
                        </tr>
                    }
                    {type === 'order' &&
                        <tr className="summary">
                            <td className="summaryLabel">Загальна вартість:</td>
                            <td className="hyphen">-</td>
                            <td className="price fielddata">{summ ? summ : 0}</td>
                        </tr>
                    }
                </tbody>
            </table>
            {type === 'config' && uInput.show &&
                <UserInput src={{Name:'', Price:0, Quantity:1, quanType:'Разова', Default:false}} obj={uInput}
                    onChange={(newItem) => {
                        let present = findService([...obj], newItem.Name);
                        if(!present) {
                            let array = [...obj, newItem];
                            onChange(array);
                            setUInput({
                                show: false,
                                type: '',
                            });
                        } else {
                            alert("Така послуга вже присутня! Оберіть, будь ласка, іншу назву.");
                        }
                    }}
                    onClick={(e) => {
                        setUInput({
                            show: false,
                            type: '',
                        });
                    }} />
            }
            {type === 'order' && uInput.show &&
                <UserInput src={src} obj={uInput}
                    onChange={(newItem) => {
                        newItem.Default = false;
                        let array = [...obj];
                        let def = array.filter(value => (value.Default === true) && (value.Name === newItem.Name));
                        let nDef = array.filter(value => (value.Default === false) && (value.Name === newItem.Name));
                        if(nDef.length > 0 && nDef[0].quanType !== "Разова") {
                            nDef[0].Quantity += 1;
                        } else if((def.length > 0 && def[0].quanType === "Разова") ||
                                  (nDef.length > 0 && nDef[0].quanType === "Разова")) {
                            alert('Така разова послуга вже додана!');
                        } else {
                            array.push(newItem);
                        }
                        addIDsToArray(array);
                        onChange(array);
                        setUInput({
                            show: false,
                            type: '',
                        });
                    }}
                    onClick={(e) => {
                        setUInput({
                            show: false,
                            type: '',
                        });
                    }} />
            }
        </>
    );
});

export default Services;