import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import BasePage from "./basePage";
import PageName from "./PageName";

const Boilies = observer( () => {

    useEffect(() => {
        document.querySelector('meta[name="og:description"]').setAttribute(
            'content',
            'Бессарабський бойл | Бойл для диких водойм | Натуральний український бойл швидкої дії | Придбати'
        );
        document.querySelector('title[id="mainTitle"]').textContent =
            'Бессарабський бойл | Онлайн-сервіс Забронюй!';
    }, [])

    return (
        <BasePage>
            <PageName name='Бессарабський Бойл' />
            <article className="pageBlock aboutArticle">
                <header>
                    <h3>Новинка!</h3>
                    <h3>Тепер у нас Ви можете придбати бойли для риболовлі.</h3>
                </header>
                <br />
                <p>Це - варений бойл з натуральних компонентів вітчизняного виробництва. З імпортних складових в ньому тільки два додаткових компонента.</p>
                <p>У складі продукту відсутні підфарбовувачі і додаткові ароматизатори, тож запах і колір натуральні.</p>
                <p>Наш бойл гарно ловить коропа/амура/карася в диких водоймах. Власне, для таких водойм він і розроблявся.</p>
                <p>Але й вибаглива риба в ставках не знехтує шансом поласувати класикою.</p>
                <p>Бойл зроблений на основі макухи (соняшникового жмиху). А це є класичний вітчизняний смак, який з давніх давен подобався нашій рибі.</p>
                <br />
                <p>Ми виробляємо два варіанти такого бойлу:</p>
                <div className="fieldbase benefits">
                    <div className="fielddata benefit"> - насадочний, діаметром 14мм;</div>
                    <div className="fielddata benefit"> - насадочно-кормовий, діаметром 20мм.</div>
                </div>
                <br />
                <p>"Механіка" нашого бойла дещо відрізняється від механіки звичних всім бойлів.</p>
                <p>Хоча бойл і варений, у воді він набагато швидше змінює свою структуру на м'яку, ніж звичні бойли. При цьому, він досить гарно зберігає форму.</p>
                <p>У спокійній воді бойл протримається на волосі досить довго (більше 2 годин). Одначе, якщо у водоймі є активна дрібна фауна, скоріш за все цей бойл їй теж сподобається. Але навіть в такому випадку бойл протримається біля години в теплій воді з течією.</p>
                <p>Тож перевага нашого бойлу в тому, що він значно швидше працює, і гарно підходить для коротких сесій.</p>
                <br />
                <p>Для замовлення звертайтеся за одним з наступних контактів:</p>
                <div className="fieldbase benefits">
                    <div className="fielddata benefit"><a target="_blank" rel="noopener noreferrer" href="tel:+380984847574">Подзвонити (з 9:00 до 19:00)</a></div>
                    <div className="fielddata benefit"><a target="_blank" rel="noopener noreferrer" href="https://t.me/ZabronujService">Написати в Телеграм</a></div>
                </div>
            </article>
            <div className="boiliesList">
                <div className="pageBlock boilieItem">
                    <img className="pageBlock boilieImage" src={`${process.env.REACT_APP_API_URL}/boilie14mm.jpg`} alt="boilie14mm"></img>
                    <div className="">Бойл варений Макуха, 14мм</div>
                    <div className="fieldbase benefits">
                        <div className="fielddata benefit boilieStock" style={{
                            // color: "#ff8c00"
                            color: "#6b8e23"
                            // color: "#c73d06"
                        }}>В наявності</div>
                        <div className="fielddata benefit">50 грн/50гр.</div>
                    </div>
                </div>
                <div className="pageBlock boilieItem">
                    <img className="pageBlock boilieImage" src={`${process.env.REACT_APP_API_URL}/boilie20mm.jpg`} alt="boilie20mm"></img>
                    <div className="">Бойл варений Макуха, 20мм</div>
                    <div className="fieldbase benefits">
                        <div className="fielddata benefit boilieStock" style={{
                            color: "#ff8c00"
                        }}>Готуємо...</div>
                        <div className="fielddata benefit">150 грн/кг</div>
                    </div>
                </div>
            </div>
        </BasePage>
    );
});

export default Boilies;
