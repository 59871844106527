import React, { useEffect } from "react";
import BasePage from "../components/basePage";
import PageName from "../components/PageName";

const Confidential = () => {

    useEffect(() => {
        document.querySelector('meta[name="og:description"]').setAttribute(
            'content',
            'Інформація про політику конфіденційності'
        );
        document.querySelector('title[id="mainTitle"]').textContent =
            'Політика конфіденційності | Онлайн-сервіс Забронюй!';
    }, []);

    return (
        <BasePage>
            <PageName name='Політика конфіденційності'/>
            <article className="pageBlock aboutArticle">
                <h3>1. Вступ</h3>
                <br />
                <p>Дана угода про конфіденційність розроблена у відповідності до вимог Закону України «Про захист персональних даних» та інших нормативних актів законодавства України, що регламентують правові відношення, пов'язані зі збором, обробкою, зберіганням персональних даних, а також правом громадян на невтручання в особисте життя і правом на самовираження.</p>
                <br />
                <p>Конфіденційність користувачів нашого веб-сайту має велике значення для нас, і ми докладаємо всіх зусиль для забезпечення її захисту. Дані правила пояснюють, як ми використовуємо Вашу персональну інформацію.</p>
                <br />
                <p>Погоджуючись із використанням файлів-cookie при першому відвіданні нашого веб-сайту, відповідно до положень даних Правил, Ви надаєте нам дозвіл на використання файлів-cookie при кожному вашому наступному візиті.</p>
            </article>
            <article className="pageBlock aboutArticle">
                <h3>2. Права інтелектуальної власності</h3>
                <br />
                <p>Цей документ був створений адміністрацією даного веб-ресурсу за зразком з веб-сайту Website Planet (<a target="_blank" rel="noopener noreferrer" href="https://www.websiteplanet.com">www.websiteplanet.com</a>)</p>
            </article>
            <article className="pageBlock aboutArticle">
                <h3>3. Збір персональних даних</h3>
                <br />
                <p>Збору, зберіганню та використанню підлягають наступні типи персональних даних:</p>
                <br />
                <ul>
                    <li>інформація, яку Ви ввели під час створення аккаунту: номер телефону, email-адреса, координати розташування;</li>
                    <li>інформація, яку Ви ввели під час бронювання часу: Ваше ім’я, номер телефону, державний реєстраційний номер автомобіля;</li>
                    <li>інформація про резервування місця (бронювання часу на водоймі): сукупна ціна послуг окремого замовлення, перелік цих послуг;</li>
                    <li>інформація, яка генерується при використанні нашого веб-сайту, включаючи інформацію про час, частоту та умови його використання;</li>
                    <li>інформація стосовно будь-яких Ваших покупок, використаних сервісів або транзакцій, які Ви провели через наш веб-сайт, включаючи Ваше ім’я, адресу, номер телефону, адресу електронної поштової скриньки та інформацію про кредитну карту;</li>
                    <li>інформація, що міститься в будь-яких повідомленнях, які Ви надсилали нам електронною поштою або через наш веб-сайт, включаючи зміст повідомлення та мета-дані;</li>
                    <li>будь-яка інша персональна інформація, яку Ви надіслали нам.
                        Перед тим, як розкрити для нас персональну інформацію третьої особи, Ви маєте отримати згоду цієї особи як на розкриття, так і на обробку цієї інформації у відповідності до даних правил.
                    </li>
                </ul>
            </article>
            <article className="pageBlock aboutArticle">
                <h3>4. Використання Вашої персональної інформації</h3>
                <br />
                <p>Персональна інформація, яку Ви передали нам через наш веб-сайт, буде використовуватися задля цілей, зазначених в цих правилах або на відповідних сторінках веб-сайту.</p>
                <p>Ми можемо використовувати Вашу персональну інформацію в наступних цілях:</p>
                <br />
                <ul>
                    <li>адміністрування нашого веб-сайту та бізнесу;</li>
                    <li>персоналізація нашого веб-сайту для Вас;</li>
                    <li>надання Вам можливості користуватися сервісами, доступними на нашому веб-сайті;</li>
                    <li>надання Вам послуг, придбаних через наш веб-сайт;</li>
                    <li>надсилання Вам повідомлень, рахунків та нагадувань про сплату, та отримання платежів від Вас;</li>
                    <li>надсилання Вам немаркетингових комерційних повідомлень;</li>
                    <li>надсилання Вам електронною поштою повідомлень, які Ви спеціально запросили;</li>
                    <li>надання стороннім компаніям статистичної інформації про наших користувачів (проте, ці сторонні компанії не матимуть змоги ідентифікувати жодного окремого користувача з цих даних);</li>
                    <li>обробка запитів та скарг, зроблених Вами або на Вас, і які стосуються нашого веб-сайту;</li>
                    <li>з метою забезпечення безпеки нашого сайту та попередження шахрайства;</li>
                    <li>з метою перевірки відповідності умовам та правилам, які регламентують використання нашого веб-сайту</li>
                    <li>в інших цілях.</li>
                </ul>
                <br />
                <p>Якщо Ви надали персональну інформацію для публікації на нашому веб-сайті, ми опублікуємо її. В іншому випадку, ми використовуватимемо цю інформацію у відповідності до ліцензії, яку Ви нам надали.</p>
                <p>Без Вашої чітко вираженої згоди ми не будемо передавати Вашу персональну інформацію жодній сторонній компанії для прямого маркетингового використання цією, або будь-якою іншою сторонньою компанією.</p>
            </article>
            <article className="pageBlock aboutArticle">
                <h3>5. Розкриття персональної інформації</h3>
                <br />
                <p>Ми лишаємо за собою право розкрити Вашу персональну інформацію для будь-якого з наших працівників, керівників, страхувальників, професійних радників, агентів, постачальників або субпідрядників, в об’ємі та цілях, визначених в цих правилах. А саме:</p>
                <ul>
                    <li>у випадках, в яких цього від нас вимагає закон;</li>
                    <li>у зв’язку з будь-яким поточними або майбутніми судовими процесами;</li>
                    <li>з метою встановлення, реалізації або захисту наших законних прав (включаючи надання інформації іншим сторонам задля запобігання шахрайству або зниження кредитних ризиків);</li>
                </ul>
                <br />
                <p>Ми зобов'язуємося не розкривати Вашу персональну інформацію третім особам, за виключенням випадків, зазначених в цих правилах.</p>
            </article>
            <article className="pageBlock aboutArticle">
                <h3>6. Міжнародні передачі персональної інформації</h3>
                <br />
                <p>Персональна інформація, які Ви публікуєте на нашому веб-сайті, через Інтернет, може бути доступна в усьому світі. Ми не можемо перешкодити її використанню, або неправомірному використанню в злочинних цілях, сторонніми особами.</p>
                <p><b><i>Погоджуючись з цими правилами, Ви надаєте згоду на передачу Вашої персональної інформації, зазначеної в цьому розділі.</i></b></p>
            </article>
            <article className="pageBlock aboutArticle">
                <h3>7. Збереження персональної інформації</h3>
                <br />
                <p>Цей розділ встановлює правила та процедури щодо збереження персональної інформації.</p>
                <p>Дані правила та процедури призначені для забезпечення виконання нами наших юридичних зобов’язань щодо збереження та видалення персональної інформації.</p>
                <p>Персональна інформація, яку ми обробляємо з певною метою або в певних цілях не повинна зберігатися довше, ніж потрібно для досягнення цієї мети або цих цілей.</p>
                <br />
                <p>Без обмежень положень, зазначених в попередньому абзаці цього розділу, ми зазвичай видаляємо персональну інформацію, що підпадає під визначені нижче категорії, в дні та час, що визначені нижче:</p>
                <br />
                <ul>
                    <li>інформація, яку Ви ввели під час створення аккаунту: в момент видалення облікового запису за Вашим запитом;</li>
                    <li>інформація про резервування місця (бронювання часу на водоймі): через 60 (шістдесят) днів після кінцевої дати броні;</li>
                    <li>інформація, яку Ви ввели під час бронювання часу: через 60 (шістдесят) днів після кінцевої дати броні, разом із записом цієї броні;</li>
                    <li>інформація, яка генерується при використанні нашого веб-сайту: за Вашим запитом;</li>
                    <li>інформація стосовно будь-яких Ваших покупок, використаних сервісів або транзакцій: за Вашим запитом;</li>
                    <li>інформація, що міститься в будь-яких повідомленнях, які Ви надсилали нам електронною поштою або через наш веб-сайт: за Вашим запитом;</li>
                </ul>
                <br />
                <p>Незважаючи на інші положення поточного розділу, ми будемо зберігати документи (включаючи електронні документи), які містять персональну інформацію:</p>
                <br />
                <ul>
                    <li>в тих випадках, в яких цього від нас вимагає закон;</li>
                    <li>якщо ми вважатимемо, що ці документи можуть мати відношення до будь-якого поточного або майбутнього судового розгляду;</li>
                    <li>з метою встановлення, реалізації або захисту наших законних прав (включаючи надання інформації іншим сторонам задля запобігання шахрайству або зниження кредитних ризиків).</li>
                </ul>
            </article>
            <article className="pageBlock aboutArticle">
                <h3>8. Захист вашої персональної інформації</h3>
                <br />
                <p>Ми будемо вживати всіх необхідних технічних та організаційних заходів для попередження втрати, протиправного використання чи підробки Вашої персональної інформації.</p>
                <br />
                <p>Всю надану Вами персональну інформацію ми будемо зберігати на наших захищених (як паролем, так і фаєрволами) серверах.</p>
                <br />
                <p>Всі електронні фінансові транзакції, здійснені за допомогою нашого сайту, будуть захищені технологією шифрування даних.</p>
                <br />
                <p>Ви підтверджуєте своє ознайомлення з тим фактом, що передача інформації через Інтернет по суті є незахищеною, і ми не можемо гарантувати захист даних, надісланих через всесвітню мережу.</p>
                <br />
                <p>Ви несете повну відповідальність за збереження свого пароля для доступу на наш веб-сайт в таємниці. Ми ніколи не будемо запитувати Ваш пароль (за виключенням випадків, коли ви намагаєтесь увійти до свого облікового запису на нашому сайті).</p>
            </article>
            <article className="pageBlock aboutArticle">
                <h3>9. Зміни та поправки</h3>
                <br />
                <p>Ми лишаємо за собою право періодично вносити зміни та поправки в ці правила, та публікувати їх нову редакцію на нашому сайті. Ви повинні періодично перевіряти цю веб-сторінку, щоб пересвідчитись, що розумієте зміст змін, внесених до цих правил.</p>
                <p>Ми також можемо проінформувати Вас про внесення змін до цих правил шляхом надсилання електронної пошти або через систему передачі приватних повідомлень нашого сайту.</p>
            </article>
            <article className="pageBlock aboutArticle">
                <h3>10. Ваші права</h3>
                <br />
                <p>Ви можете надати нам вказівку надавати вам будь-яку персональну інформацію про Вас, яку ми маємо.</p>
                <p>Ви маєте право надати нам вказівку не обробляти Вашу персональну інформацію в маркетингових цілях.</p>
                <p>Ми лишаємо за собою право відмовити в наданні інформації за Вашим запитом, в межах чинного законодавства.</p>
            </article>
            <article className="pageBlock aboutArticle">
                <h3>11. Сторонні веб-сайти</h3>
                <br />
                <p>Наш веб-сайт може містити гіперпосилання на, та певні деталі про веб-сайти сторонніх компаній та осіб. Ми не маємо інструментів керування та не несемо відповідальності за політику конфіденційності і практики сторонніх осіб та компаній в цій галузі.</p>
            </article>
            <article className="pageBlock aboutArticle">
                <h3>12. Оновлення інформації</h3>
                <br />
                <p>Будь-ласка, своєчасно повідомляйте нас, якщо Ваша персональна інформація, яка знаходиться у нас, потребує оновлення чи виправлень.</p>
            </article>
            <article className="pageBlock aboutArticle">
                <h3>13. Файли-Cookies</h3>
                <br />
                <p>Наш веб-сайт використовує файли-cookies. Cookie — це файл, що містить ідентифікатор (стрічку, яка складається з літер та цифр), і який надсилається веб-сервером до веб-браузеру та зберігається браузером. В подальшому, ідентифікатор надсилається назад на сервер кожного разу, коли браузер запитує веб-сторінку з серверу. Файли-cookies можуть бути або «постійні» або «сеансові»: <b><i>постійні</i></b> будуть зберігатися браузером та будуть придатними до завершення терміну дії, якщо тільки не будуть видалені користувачем завчасно; <b><i>сеансові</i></b> навпаки – будуть видалятися після завершення сеансу роботи з сайтом або після закриття браузеру. Файли-cookies зазвичай не містять жодної інформації, яка ідентифікує особу користувача. Проте, Ваша персональна інформація, яку ми маємо, може бути пов’язана з інформацією, що зберігається та отримана від файлів-cookies.</p>
                <br />
                <p>На нашому веб-сайті ми використовуємо як постійні, так і сеансові файли-cookies.</p>
                <p>Назви файлів-cookies, які ми використовуємо на нашому веб-сайті, та цілі, задля яких вони використовуються, зазначені нижче:</p>
                <br />
                <ul>
                    <li><b><i>accessToken</i></b> - використовується виключно для авторизації користувача;</li>
                    <li><b><i>refreshToken</i></b> - використовується виключно для оновлення токена <b><i>accessToken</i></b>;</li>
                    <li><b><i>UserID</i></b> - використовується виключно для персоналізації функціоналу сайту під окремого користувача.</li>
                </ul>
                <br />
                <p>Більшість браузерів надають Вам можливість відмовитися від використання файлів-cookies, наприклад:</p>
                <br />
                <ul>
                    <li>в <b><i>Internet Explorer</i></b> (версія 10) Ви можете заблокувати їх, використовуючи налаштування керування файлами-cookie, доступними в меню «Інструменти» – «Опції Інтернету» – «Конфіденційність» – «Розширені» ( “Tools,” “Internet Options,” “Privacy,” “Advanced”);</li>
                    <li>у <b><i>Firefox</i></b> (версія 24) Ви можете заблокувати всі файли-cookie, натиснувши «Інструменти» – «Опції» – «Конфіденційність»: у випадаючому меню оберіть пункт «Використовувати користувацькі налаштування журналу» та зніміть виділення з пункту «Прийняти файли-cookie від сайтів»;</li>
                    <li>в <b><i>Chrome</i></b> (версія 29) Ви можете заблокувати всі файли-cookie увійшовши до меню «Налаштування та управління», та обравши «Налаштування» – «Відобразити розширені налаштування» та «Налаштування контенту», а потім обравши «Заборонити сайтам надсилати будь-які дані» під заголовком «Cookies».</li>
                </ul>
                <br />
                <p>Блокування всіх файлів-cookiе матиме негативні наслідки на зручність користування багатьма веб-сайтами. Якщо Ви заблокуєте файли-cookie, ви не зможете користуватися деякими функціями нашого веб-сайту!</p>
                <br />
                <p>Ви можете видалити файли-cookie, які вже зберігаються на вашому комп’ютері, наприклад:</p>
                <br />
                <ul>
                    <li>в <b><i>Internet Explorer</i></b> (версія 10), Ви маєте видаляти файли-cookie вручну (інструкцію, як це зробити, можна знайти за адресою <a href="http://support.microsoft.com/kb/278835">http://support.microsoft.com/kb/278835</a>);</li>
                    <li>у <b><i>Firefox</i></b> (версія 24), файли-cookie можна видалити перейшовши в меню «Інструменти» – «Опції» – «Конфіденційність»: у випадаючому меню оберіть пункт «Використовувати користувацькі налаштування журналу», натисніть “Показати Cookies,” а потім – “Видалити всі Cookies”;</li>
                    <li>в <b><i>Chrome</i></b> (версія 29) Ви можете видалити всі файли-cookie увійшовши до меню «Налаштування та управління», та обравши «Налаштування» – «Відобразити розширені налаштування» та «Очистити дані перегляду», а перед тим оберіть пункт «Видалити файли-cookie та інші дані й плагіни сайтів».</li>
                </ul>
            </article>
        </BasePage>
    );
};

export default Confidential;