import React from "react";
import { LAKELIST_ROUTE } from "../env";
import { Link } from "react-router-dom";

const Offer = () => {
    return (
        <div className='pageBlock publicOffer'>
            <h2 style={{ fontSize: '18px' }}>ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ ПРО НАДАННЯ ПОСЛУГ З ВИКОРИСТАННЯМ ОНЛАЙН ВЕБ-РЕСУРСУ</h2>
            <br /><br />
            <p>&emsp;&emsp;Онлайн-сервіс “Забронюй!” (<b><i>ФОП Колесник Олексій Сергійович</i></b>) (надалі -
                “Виконавець"), в особі директора Олексія Сергійовича Колесника з однієї сторони, цим
                документом пропонує необмеженому колу осіб, які ведуть господарську або волонтерську
                діяльність на водоймах (надалі - "Замовник"), з іншої сторони (надалі разом - "Сторони") –
                акцептувати цю Публічну Оферту (укласти договір про надання послуг з онлайн
                бронювання місць на водоймі).
            </p><br />
            <p>&emsp;&emsp;Дана Оферта є публічною і, згідно ст. ст. 633, 641 ЦК України, її основні умови
                однакові для всіх Замовників незалежно від їх статусу (фізичних особа, юридичних особа,
                або фізична особа-підприємець). Беззастережне прийняття всіх умов даного Договору без
                будь-яких винятків і/або обмежень вважається акцептом даного Договору Виконавцем і
                Замовником, а сам Договір автоматично вважається укладеним.
            </p>
            <br /><br />
            <h3>1. Терміни</h3>
            <br />
            <p>&emsp;1.1.&emsp;Публічна оферта (надалі - “Договір”) – пропозиція Виконавця (викладена на
                веб-сайті Виконавця), адресована невизначеному колу осіб, укласти Договір про
                дистанційне надання послуг на умовах, що містяться в цій Оферті.
            </p><br />
            <p>&emsp;1.2.&emsp;Договір – правочин, складений у формі договору приєднання відповідно до ст.
                634 Цивільного кодексу України про надання послуг, умови якого встановлені
                Виконавцем, і який може бути укладено лише шляхом приєднання Замовника до
                запропонованого Договору в цілому.
            </p><br />
            <p>&emsp;1.3.&emsp;Cайт Виконавця (далі - “Сайт”) – веб-сторінка сервісу в мережі Інтернет, за
                адресою <Link to={LAKELIST_ROUTE}>https://zabronuj.com.ua</Link>, яка є основним джерелом інформування Замовника і/або
                може бути використана Замовником для замовлення послуг Виконавця.
            </p><br />
            <p>&emsp;1.4.&emsp;Замовник – юридична особа або фізична особа-підприємець, яка веде
                господарську або волонтерську діяльність на водоймі, і яка користується цією водоймою
                відповідно до Закону України (на підставі оренди або статуту і завдань відповідної
                громадської організації).
            </p><br />
            <p>&emsp;1.5.&emsp;Виконавець – ФОП Колесник Олексій Сергійович (ідентифікаційний код
                3220314019), фізична особа-підприємець, яка створена і діє відповідно до чинного
                законодавства України, місцезнаходження якої: місто Одеса, вул. Академіка Корольова,
                буд. 28.
            </p><br />
            <p>&emsp;1.6.&emsp;Користувач — кліент Замовника (відвідувач водойми Замовника), який
                використовує функціонал Сайту Виконавця для ознайомлення з інформацією про водойму
                та/або для бронювання місця.
            </p><br />
            <p>&emsp;1.7.&emsp;Послуги Виконавця (далі - “Послуги”) — надання Замовнику доступу до і
                щоденна підтримка функціоналу веб-сайту з онлайн-адміністрування водойми а також
                бронювання місць на водоймі відвідувачами.
            </p><br />
            <p>&emsp;1.8.&emsp;Акцепт – повне, безумовне та беззастережне прийняття Замовником умов
                визначених цією Офертою без будь-яких виключень, обмежень та застережень згідно із
                частиною 2 статті 642 Цивільного кодексу України.
            </p><br />
            <p>&emsp;1.9.&emsp;Авторизований доступ – доступ до послуг Виконавця за умови успішного
                засвідчення правомірності дистанційного звернення користувача до серверу Виконавця,
                що здійснюється за допомогою автоматично згенерованого сервером Виконавця коду
                (токену).
            </p><br />
            <p>&emsp;1.10.&emsp;Облікові дані – дані Замовника, які використовуються для його ідентифікації та
                надання авторизованого доступу до веб-ресурсу та/або мобільного додатку сервісу.
            </p><br />
            <p>&emsp;1.11.&emsp;Персональні дані – відомості чи сукупність відомостей про особу, яка
                ідентифікована або може бути конкретно ідентифікована.
            </p><br />
            <p>&emsp;1.12.&emsp;Згода суб’єкта персональних даних – добровільне волевиявлення особи, яка надає дозвіл на обробку її персональних даних відповідно до мети обробки.
            </p><br />
            <p>&emsp;1.13.&emsp;Обробка персональних даних – будь-яка дія або сукупність дій, таких як
                збирання, реєстрація, накопичення, зберігання, адаптування, зміна, поновлення,
                використання і поширення (розповсюдження, реалізація, передача), знеособлення,
                знищення персональних даних, у тому числі з використанням інформаційних
                (автоматизованих) систем.
            </p><br />
            <p>&emsp;&emsp;Акцепт може бути здійснений одним з наступних способів:
                <ul style={{ paddingLeft: '20px' }}>
                    <li>&emsp;реєстрація Замовника в системі онлайн-сервісу Виконавця;</li>
                    <li>&emsp;перерахуванням грошових коштів на банківський рахунок Виконавця в якості
                        оплати або часткової оплати за відповідні Послуги Виконавця;</li>
                    <li>&emsp;розміщенням Замовником Замовлення на Послуги Виконавця за допомогою сайту
                        Виконавця і/або за допомогою мобільного додатку Виконавця.</li>
                </ul>
            </p>
            <br /><br />
            <h3>2. Предмет Договору</h3>
            <br />
            <p>&emsp;2.1.&emsp;В порядку та на умовах, визначних цим Договором, Виконавець зобов'язується
                надавати Замовнику Послуги, а Замовник зобов'язується приймати такі Послуги а також
                оплачувати їх, якщо інше не встановлено умовами цього Договору.
            </p><br />
            <p>&emsp;2.2.&emsp;Датою укладення цього Договору-Оферти (акцептом оферти) та моментом
                повного й беззаперечного прийняття Замовником умов Договору вважається дата
                реєстрації Замовника (отримання ним авторизованого доступу) в системі онлайн-сервісу Виконавця.
            </p><br />
            <p>&emsp;2.3.&emsp;Наявність інформації про водойму Замовника в системі Сайту, а також присутність
                такої водойми в переліку водойм на Сайті, самі по собі, не можуть сприйматися як факт реєстрації
                Замовника на Сайті і, відповідно, не накладають на Сторони жодних зобов'язань за цим Договором.
            </p><br />
            <p>&emsp;2.4.&emsp;Виконавець не несе відповідальності за будь-який несанкціонований доступ або
                використання онлайн-сервісу та/або будь-якої інформації, що зберігається на сервері
                Сайту, а також за будь-які помилки, збої, віруси, і інші недоліки та порушення в
                мобільному додатку або веб-браузері, які можуть бути передані на пристрій Замовника
                або Користувача третіми особами та їх наслідки, у т.ч. але не виключно: за збитки,
                упущену вигоду, моральну шкоду, шкоду честі, гідності та ділової репутації.
            </p><br />
            <p>&emsp;2.5.&emsp;У разі необхідності, за бажанням Замовника, Договір може бути оформлений у
                письмовій формі.
            </p>
            <br /><br />
            <h3>3. Права й обов'язки Сторін</h3>
            <br />
            <p>&emsp;3.1. Виконавець зобов'язаний:
                <ul style={{ paddingLeft: '20px' }}>
                    <li>&emsp;на протязі всього строку дії цього Договору надавати Замовнику постійний доступ
                        до веб-сайту онлайн-сервісу, за виключенням періодів технічних робіт;</li>
                    <li>&emsp;не розголошувати будь-яку приватну інформацію про Замовника і не надавати
                        доступ до цієї інформації третім особам, за винятком випадків, передбачених
                        законодавством та під час виконання визначених цим Договором зобов'язань перед
                        Замовником;</li>
                    <li>&emsp;належно реагувати на будь-які ознаки шахрайства, вчиненого з використанням
                        онлайн-сервісу Виконавця, або фактичні його прояви;</li>
                </ul>
            </p><br />
            <p>&emsp;3.2. Виконавець має право:
                <ul style={{ paddingLeft: '20px' }}>
                    <li>&emsp;отримувати від Замовника необхідну актуальну інформацію, необхідну для надання
                        послуг за цим Договором;</li>
                    <li>&emsp;отримувати за надані Послуги оплату в розмірах і строки, передбачені Договором;</li>
                    <li>&emsp;змінювати умови цього Договору, а також ціни на товари та Послуги, в
                        односторонньому порядку, розміщуючи їх на Сайті Виконавця. Всі зміни
                        набувають чинності з моменту їх публікації;</li>
                    <li>&emsp;регулярно впроваджувати зміни до функціоналу Сайту задля покращення якості
                        надання послуг Замовнику;</li>
                    <li>&emsp;залучати для здійснення своїх обов’язків третіх осіб, які мають необхідну
                        кваліфікацію для надання конкретного виду Послуги;</li>
                    <li>&emsp;розірвати цей Договір в односторонньому порядку, завчасно (не менш, ніж за два тижні — 14 днів) повідомивши про це Замовника.</li>
                </ul>
            </p><br />
            <p>&emsp;3.3. Замовник зобов'язаний:
                <ul style={{ paddingLeft: '20px' }}>
                    <li>&emsp;до моменту укладення Договору ознайомитися зі змістом Договору, умовами
                        Договору і цінами, запропонованими Виконавцем на Сайті сервісу ;</li>
                    <li>&emsp;приймати від Виконавця результати надання послуг, якщо надані Послуги
                        відповідають умовам Договору, і оплачувати їх в розмірах і в строк, передбачених
                        Договором;</li>
                    <li>&emsp;забезпечувати Виконавця актуальною інформацією, необхідною для надання
                        послуг;</li>
                    <li>&emsp;дотримуватися законодавства України, що стосується охорони авторських прав
                        Виконавця.</li>
                </ul>
            </p><br />
            <p>&emsp;3.4. Замовник має право:
                <ul style={{ paddingLeft: '20px' }}>
                    <li>&emsp;вимагати належної якості надання послуг Виконавцем згідно умов цього Договору;</li>
                    <li>&emsp;робити запити до адміністратора Сайту з метою видалення його (Замовника)
                        персональних даних;</li>
                    <li>&emsp;надавати свої рекомендації і побажання щодо покращення якості сервісу;</li>
                    <li>&emsp;відмовитись від подальшого користування Послугами Виконавця (розірвати цей
                        Договір) з будь-яких причин, письмово попередивши про це Виконавця не пізніше,
                        ніж за два тижні до моменту фактичного припинення користування (розірвання
                        Договору).</li>
                </ul>
            </p>
            <br /><br />
            <h3>4. Оплата послуг і порядок їх прийняття</h3>
            <br />
            <p>&emsp;4.1.&emsp;Ціни на товари та Послуги визначаються Виконавцем самостійно та вказані на
                сайті сервісу. Всі ціни на товари та Послуги вказані на Cайті у гривнях (UAH).
            </p><br />
            <p>&emsp;4.2.&emsp;Ціни на товари та Послуги можуть змінюватися Виконавцем в
                односторонньому порядку залежно від кон'юнктури ринку. При цьому, ціна окремої
                одиниці товару або Послуги, вартість якої оплачена Замовником в повному обсязі, не
                може бути змінена Виконавцем в односторонньому порядку.
            </p><br />
            <p>&emsp;4.3.&emsp;Оплата наданих послуг за цим Договором здійснюється Замовником у строк 10
                (десять) днів після закінчення кожного місяця (30 днів), упродовж якого
                фактично надавалися ці Послуги.
            </p><br />
            <p>&emsp;4.4.&emsp;Зобов'язання Замовника щодо прийняття і оплати товарів або послуг
                вважаються виконаними з моменту надходження Виконавцю повної суми коштів за надані
                товари або Послуги на розрахунковий рахунок Виконавця.
            </p><br />
            <p>&emsp;4.5.&emsp;Надані Послуги вважаються прийнятими Замовником у повному обсязі в
                момент надходження Виконавцю повної суми коштів за надані Послуги на розрахунковий
                рахунок Виконавця.
            </p><br />
            <p>&emsp;4.6.&emsp;Надання Послуг та приймання їх результатів, на прохання Замовника, може
                оформлюватися окремим Актом приймання-передачі наданих послуг, який підписується
                повноважними представниками Сторін протягом 3 (трьох) робочих днів після закінчення
                кожного місяця (30 днів), упродовж якого фактичного надавалися Послуги.
            </p><br />
            <p>&emsp;4.7.&emsp;Підписання Акту приймання-передачі наданих послуг представником
                Замовника, або сплата Замовником на рахунок Виконавця повної суми вартості
                замовлених послуг є підтвердженням відсутності претензій до Виконавця з боку
                Замовника.
            </p><br />
            <p>&emsp;4.8.&emsp;За надання послуг онлайн-адміністрування водойми і онлайн-бронювання місць
                Замовник виплачує Виконавцю суму коштів у розмірі 1% (один відсоток) від суми всіх
                операцій замовлення місця на водоймі Замовника, які були здійснені з використанням
                функціоналу Сайту Виконавця.
            </p><br />
            <p>&emsp;4.9.&emsp;Замовнику, який є громадською організацією, що проводить волонтерську
                діяльність на окремій водоймі або на декількох водоймах і не проводить на таких
                водоймах комерційної діяльності, Послуги Виконавця можуть надаватися безкоштовно.
                Такий Замовник має надати Виконавцю всі необхідні документи, що підтверджують законну діяльність такої громадської організації і її цілі. До такого Замовника не
                застосовуються норми цього Договору, які стосуються оплати Послуг.
            </p><br />
            <p>&emsp;4.10.&emsp;За умовами Договору, перший місяць (30 днів) користування послугами сервісу є безкоштовним.
                В цей період може нараховуватись загальна сума резервів, але рахунок в кінці цього періоду не виставляється Замовнику.
            </p>
            <br /><br />
            <h3>5. Відповідальність Сторін і вирішення спорів</h3>
            <br />
            <p>&emsp;5.1.&emsp;У випадку порушення своїх зобов'язань за цим Договором Сторони несуть
                відповідальність, визначену цим Договором та чинним в Україні законодавством.
                Порушенням зобов'язання є його невиконання або неналежне виконання, тобто виконання
                з порушенням умов, визначених змістом зобов'язання у цьому Договорі.
            </p><br />
            <p>&emsp;5.2.&emsp;Сторони не несуть відповідальності за порушення своїх зобов'язань за цим
                Договором, якщо воно сталося не з їх вини або через обставини форс-мажору. Сторона
                вважається невинуватою, якщо вона доведе, що вжила всіх залежних від неї заходів для
                належного виконання зобов'язання.
            </p><br />
            <p>&emsp;5.3.&emsp;За прострочення у виплаті Виконавцю передбачених цим Договором коштів
                Замовник за кожний календарний тиждень прострочення платежу сплачує Виконавцю
                пеню від суми заборгованості в розмірі подвійної облікової ставки НБУ за кожен тиждень
                прострочення.
            </p><br />
            <p>&emsp;5.4.&emsp;Сплата штрафних санкцій не звільняє Сторони від виконання зобов'язань по
                Договору.
            </p><br />
            <p>&emsp;5.5.&emsp;Виконавець володіє винятковими правами на об’єкти права інтелектуальної
                власності, пов’язані з онлайн-сервісом "Забронюй!", у т.ч. на торговельні марки (знаки для
                товарів і послуг), на об'єкти авторських прав тощо, включаючи, але не обмежуючись, на
                програми та сервіси, за допомогою яких онлайн-сервіс реалізований. Будь-яке
                використання програм та сервісів Виконавця не призводить до передачі Замовнику будь-
                яких прав інтелектуальної власності на них.
            </p><br />
            <p>&emsp;5.6.&emsp;Виконавець не несе відповідальність за зміст даних Користувача або
                Замовника, а також за зміст будь-якої інформації, що вноситься ними.
            </p><br />
            <p>&emsp;5.7.&emsp;Усі спори, що пов'язані із цим Договором, його укладанням або такі, що
                виникають в процесі виконання умов цього Договору, вирішуються шляхом переговорів
                між представниками Сторін. Якщо спір не можливо вирішити шляхом переговорів, він
                вирішується в судовому порядку за встановленою підвідомчістю та підсудністю такого
                спору у порядку, визначеному відповідним чинним в Україні законодавством.
            </p>
            <br /><br />
            <h3>6. Строк дії Договору та інші умови</h3>
            <br />
            <p>&emsp;6.1.&emsp;Цей Договір набуває чинності з моменту прийняття (акцепту) даного Договору-Оферти і діє до моменту волевиявлення тієї чи іншої Сторони розірвати цей Договір,
                включно.
            </p><br />
            <p>&emsp;6.2.&emsp;Після підписання цього Договору всі попередні переговори за ним, листування,
                попередні угоди та протоколи про наміри з питань, що так чи інакше стосуються цього
                Договору, втрачають юридичну силу.
            </p><br />
            <p>&emsp;6.3.&emsp;Виконавець може проводити аудіозапис всіх розмов із Замовником без
                додаткового повідомлення Замовника про це. Дана інформація є конфіденційною.
            </p><br />
            <p>&emsp;6.4.&emsp;У разі відмови від подальшого користування Послугами Виконавця з будь-яких
                причин (розірвання Договору), Замовник має попередити про це Виконавця не пізніше,
                ніж за два тижні до моменту запланованого припинення користування.
            </p><br />
            <p>&emsp;6.5.&emsp;Зміни та доповнення, додаткові угоди та додатки до цього Договору є його
                невід'ємною частиною і мають таку ж саму юридичну силу, як і сам Договір.
            </p><br />
            <p>&emsp;6.6.&emsp;Усі правовідносини, що виникають у зв'язку з виконанням умов цього Договору
                і не врегульовані ним, регламентуються нормами чинного в Україні законодавства.
            </p>
            <br /><br />
            <h3>7. Місцезнаходження та реквізити</h3>
            <br />
            <p style={{ fontStyle: 'italic' }}>
                <h4>&emsp;&emsp;&emsp;&emsp;Виконавець:</h4>
                <br />
                <h4>&emsp;ФОП Колесник Олексій Сергійович</h4>
                <br />
                <h4>&emsp;Адреса: м.Одеса, вул. Академіка Корольова, 28</h4>
                <br />
                <h4>&emsp;IBAN: UA263808050000000026003385742</h4>
                <br />
                <h4>&emsp;МФО: 380805 у АТ "Райффайзен Банк"</h4>
                <br />
            </p>
        </div>
    );
};

export default Offer;