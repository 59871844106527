import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Context } from "..";
import OrderForm from "./OrderForm";

const OrderStatus = observer( ({type, status, obj, day, sublakes, sublake, place, onChange}) => {
    const [orderPopup, setOrderPopup] = useState(false);
    const [popupType, setPopupType] = useState('fisherInfo');

    return (
        <td>
            <div className='fielddata advanceField placestatus'>
                {status === 'Busy' ? 'Зайняте' : 'Зарезервоване'}
                {(type === 'admin' || status === 'Reserved') &&
                    <button className='button contextButton Dialog' type="button" onClick={e => {
                        if(type === 'admin')
                            setPopupType('fisherInfo');
                        else
                            setPopupType('fisherAdd');
                        setOrderPopup(true);
                    }}>...</button>
                }
            </div>
            {orderPopup &&
                <OrderForm day={day} sublakes={sublakes} type={popupType} sublake={sublake} place={place} obj={obj} onClick={(e) => {
                    setOrderPopup(false);
                }}
                onChange={() => {
                    setOrderPopup(false);
                    onChange();
                }} />
            }
        </td>
    );
});

const LakeSchemePlace = observer( ({type, day, sublakes, sublake, obj, onChange}) => {
    const {user} = useContext(Context);
    const [orderPopup, setOrderPopup] = useState(false);
    const [popupType, setPopupType] = useState('fisherInfo');

    return (
        <>
        {(obj.Status === 'Busy' || obj.Status === 'Reserved') && obj.orders.length &&
            obj.orders.map(item => {
                let status = obj.Status;
                if(type === 'visitor' && status === 'Reserved' && !user.checkUserID(item.uID)) {
                    status = 'Busy';
                }
                return (
                    <tr key={item.startDate} className={`${status === 'Busy' ? 'placebusy' : 'placereserved'}`}>
                        {item === obj.orders[0]
                        ?   <td className='placesname fielddata' placenum={obj.Num}>
                                {obj.Num}
                            </td>
                        :   <td></td>
                        }
                        <OrderStatus type={type} status={status} obj={item} day={day}
                            sublakes={sublakes} sublake={sublake} place={obj.Num}
                            onChange={e => {
                                onChange();
                            }}
                        />
                        <td className='placetime'>
                            {item.startDate + " - " + item.endDate}
                        </td>
                    </tr>
                )
            })
        }
        {(obj.Status === 'Busy' || obj.Status === 'Reserved') &&
            <tr className='placefree'>
                <td></td>
                <td></td>
                <td className='placeButtonOwn'>
                    <button className='button buttonAddFisher' type="button" onClick={e => {
                        setPopupType('fisherAdd');
                        setOrderPopup(true);
                    }}>{type === 'visitor' ? 'Зайняти' : 'Добавити'}</button>
                    {orderPopup &&
                        <OrderForm day={day} sublakes={sublakes} type={popupType} sublake={sublake} place={obj.Num} obj={null} onClick={(e) => {
                            setOrderPopup(false);
                        }}
                        onChange={() => {
                            setOrderPopup(false);
                            onChange();
                        }} />
                    }
                </td>
            </tr>
        }
        {obj.Status === 'Free' &&
            <tr className='placefree'>
                <td className='placesname fielddata' placenum={obj.Num}>
                    {obj.Num}
                </td>
                <td className='placestatus'>
                    {'Вільне'}
                </td>
                <td className='placeButtonOwn'>
                    <button className='button buttonAddFisher' type="button" onClick={e => {
                        setPopupType('fisherAdd');
                        setOrderPopup(true);
                    }}>{type === 'visitor' ? 'Зайняти' : 'Добавити'}</button>
                    {orderPopup &&
                        <OrderForm day={day} sublakes={sublakes} type={popupType} sublake={sublake} place={obj.Num} obj={null} onClick={(e) => {
                            setOrderPopup(false);
                        }}
                        onChange={() => {
                            setOrderPopup(false);
                            onChange();
                        }} />
                    }
                </td>
            </tr>
        }
        {obj.Status === 'Disabled' &&
            <tr className='placeinactive'>
                <td className='placesname fielddata' placenum={obj.Num}>
                    {obj.Num}
                </td>
                <td className='placestatus'>
                    {'Неактивне'}
                </td>
                <td>
                </td>
            </tr>
        }
        </>
    );
});

const LakeScheme = observer( ({type}) => {
    const {user} = useContext(Context);
    const [sublakes, setSublakes] = useState([]);
    const [day, setDay] = useState(0);
    const [mesType, setMesType] = useState(0);
    const [searchParams] = useSearchParams();

    const days = [0,1,2,3,4,5,6];

    useEffect(() => {
        init();
    }, []);

    const init = async (dayNum) => {
        if(!dayNum) dayNum = 0;
        let Data = await user.send(
                'cl_LakeScheme',
                `${!searchParams.has('name')?'/api':''}/changeDay${searchParams.has('name')?'?'+searchParams:''}`,
                {'Day':dayNum, 'uID':user.getUserID()}
            );
        if(Data.Status !== 'Success') {
            switch(Data.Status) {
                case 'NOT_A_CLIENT':
                    setMesType(1);
                    break;
                default:
                    setMesType(2);
                    break;
                }
            return Data;
        }
        setSublakes(Data.Data.Sublakes);
    };

    const initDay = (index) => {
        let dayName, date;
        let dayPattern = ["Неділя","Понеділок","Вівторок","Середа","Четвер","П'ятниця","Субота",
    "Неділя","Понеділок","Вівторок","Середа","Четвер","П'ятниця","Субота"];
        let now = new Date();

        dayName = dayPattern[now.getDay()+index];
        let dItem = new Date();
        dItem.setDate(now.getDate()+index);
        date = dItem.getDate()+"."+(dItem.getMonth()+1)+"."+dItem.getFullYear();
        return {Name:dayName, Date:date};
    };

    return (
        <section id="list">
            <article className='pageBlock lakereserve lakeadmin'>
                {(sublakes && sublakes.length > 0) ?
                    <div className="fieldbase days">
                        {days.map(item => {
                            let Day = initDay(item);
                            if (item === 0) Day.Name = 'Сьогодні';
                            return <button key={item} className={`button day${day === item ? ' daychecked' : ''}`} name={item} onClick={async e => {
                                setDay(Number(e.target.name));
                                await init(Number(e.target.name));
                            }}>
                                {Day.Name}<br />{Day.Date}
                            </button>
                        })}
                    </div>
                :
                    <>
                    {mesType === 0 &&
                        <p className="nonClientInfo">Зачекайте, будь ласка...</p>
                    }
                    {mesType === 1 &&
                        <p className="nonClientInfo">Функціонал перегляду і бронювання місць наразі недоступний, тому що дана водойма, поки-що, не є клієнтом нашого сервісу. :(</p>
                    }
                    {mesType === 2 &&
                    <>
                        <p className="nonClientInfo">Функціонал перегляду і бронювання місць наразі недоступний у зв'язку з блокуванням аккаунту водойми. :(</p>
                        <p className="nonClientInfo">Приносимо вибачення за незручності!</p>
                    </>
                    }
                    </>
                }
                {sublakes && sublakes.map(item =>
                    <div key={item.Num} className='lakescheme'>
                        <header>
                            <h3 id={item.Name}>{item.Name}</h3>
                        </header>
                        <table className='places'>
                            <tbody>
                                <tr className='legend'>
                                    <td className='placeslegend'><legend className='fieldname'>Місце:</legend></td>
                                    <td className='placeslegend'><legend className='fieldname'>Статус:</legend></td>
                                    <td className='placeslegend'><legend className='fieldname'>Час:</legend></td>
                                </tr>
                                {item.places && item.places.map(place =>
                                    <LakeSchemePlace key={place.Num} type={type} day={day} sublakes={sublakes} sublake={item.Name} obj={place} onChange={async () => {
                                        await init(day);
                                    }} />
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </article>
        </section>
    );
});

const LakeSchemeLakeConfig = observer( ({obj, onChange, children}) => {
    const {user} = useContext(Context);
    const [name, setName] = useState(obj.Name);
    const [prevName, setPrevName] = useState(obj.Name);
    const [active, setActive] = useState(obj.Status);
    const [num, setNum] = useState(obj.Num);

    const apply = async (cmd, payload) => {
        let response = await user.apply('cl_LakeConfig', prevName, `/api/${cmd}`, payload);
        if(response.Status !== 'Success') return response;
        return true;
    };

    return (
        <div className={`lakescheme ${active === 'Disabled' ? 'wrapDisable' : 'wrapEnable'}`}>
            <header>
                <input className='fielddata inputText' type='text' name='LakeName' maxLength='30' placeholder='< Назва водойми >' required
                    value={name} onChange={(e) => {
                        setName(e.target.value);
                    }} onBlur={async e => {
                        if(name !== prevName) {
                            let origName = prevName;
                            if(! await apply('nameUpd', e.target.value)) {
                                setName(origName);
                            } else {
                                setPrevName(e.target.value);
                            }
                        }
                    }} />
                <div className='headerButtons'>
                    <div className='headerButtonsLeft'>
                        {active === 'Disabled'
                            ?   <button className="button buttonLakeToggle sb_enable" type="button"
                                    onClick={e => {
                                        setActive('Active');
                                        apply('toggleClose', 'Active');
                                    }}
                                >Відкрити водойму</button>
                            :   <button className="button buttonLakeToggle sb_disable" type="button"
                                    onClick={e => {
                                        setActive('Disabled');
                                        apply('toggleClose', 'Disabled');
                                    }}
                                >Закрити водойму</button>
                        }
                    </div>
                    <div className='headerButtonsRight'>
                        <button className="button buttonUp" type="button" onClick={async e => {
                            if(num > 1) setNum(num - 1);
                            if(await apply('moveUp'))
                                await onChange();
                        }}>&#11014;</button>
                        <button className="button buttonDown" type="button" onClick={async e => {
                            setNum(num + 1);
                            if(await apply('moveDown'))
                                await onChange();
                        }}>&#11015;</button>
                        <button className="button buttonRemove" type="button" onClick={async e => {
                            if(await apply('sublakeRemove'))
                                await onChange();
                        }}>-</button>
                    </div>
                </div>
            </header>
            <table className='places'>
                <tbody>
                    <tr>
                        <td className='placeslegend'><legend className='fieldname'>Місце:</legend></td>
                        <td className='placeslegend'><legend className='fieldname'>Фото:</legend></td>
                        <td className='placeslegend'><legend className='fieldname'></legend></td>
                    </tr>
                    {children}
                    <tr className='new'>
                        <td>
                            <div className='fielddata advanceField'>
                                <button className='button contextButton Plus addPlace' onClick={async e => {
                                    if(await apply('addPlace'))
                                        await onChange();
                                }}>+</button>
                            </div>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <div className='footerButtons'>
                <button className="button buttonAdd" type="button" onClick={async e => {
                    if(await apply('sublakeAdd'))
                        await onChange();
                }}>+</button>
            </div>
        </div>
    );
});

const LakeSchemePlaceConfig = observer( ({sublake, obj, onChange}) => {
    const {user} = useContext(Context);
    const [active, setActive] = useState(obj.Status);

    const apply = async (cmd, payload) => {
        let response = await user.apply('cl_LakeConfig', sublake, `/api/${cmd}`, payload);
        if(response.Status !== 'Success') return response;
        return true;
    };

    return (
        <tr>
            <td className='name'>
                <div className={`fielddata advanceField placeName ${active === 'Disabled' ? 'wrapDisable' : 'wrapEnable'}`}>
                    {obj.Num}
                    <button className="button contextButton Minus removePlace" onClick={async e => {
                        if(await apply('removePlace', obj.Num))
                            await onChange();
                    }}>-</button>
                </div> 
            </td>
            <td>
                <div className={`fielddata advanceField placePhoto ${active === 'Disabled' ? 'wrapDisable' : 'wrapEnable'}`}>
                    {"< Фото 1 >..."}
                    <button className={`button contextButton Dialog ${active === 'Disabled' ? 'wrapDisable' : 'wrapEnable'}`} type="button">...</button>
                </div>
            </td>
            <td className='placeButtonOwn'>
                <button className={`button buttonPlaceToggle ${active === 'Disabled' ? 'sb_enable' : 'sb_disable'}`} type="button"
                    onClick={async e => {
                        if(await apply('placeToggle', obj.Num)) {
                            active === 'Disabled' ? setActive('Active') : setActive('Disabled');
                            await onChange();
                        }
                    }}
                >
                    {`${active === 'Disabled' ? 'Відкрити' : 'Закрити'}`}
                </button>
            </td>
        </tr>
    );
});

const LakeSchemeConfig = observer( () => {
    const {user} = useContext(Context);
    const [sublakes, setSublakes] = useState([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let Data = await user.requestPrereq('api/getLakescheme');
        if(!Data)
            return {Status:'PREREQUISITES_ERROR'};
        setSublakes(Data.Sublakes);
    };

    return (
        <section id="list">
            <article className='pageBlock lakereserve lakeconfig'>
                {sublakes && sublakes.map(item =>
                    <LakeSchemeLakeConfig key={item.Name} obj={item} onChange={async (newObj) => {
                        await init();
                    }}>
                        {item.places && item.places.map(place =>
                            <LakeSchemePlaceConfig key={place.Num} sublake={item.Name} obj={place} onChange={async (newObj) => {
                                await init();
                            }} />
                        )}
                    </LakeSchemeLakeConfig>
                )}
            </article>
        </section>
    );
});

export {
    LakeScheme,
    LakeSchemeConfig
};
