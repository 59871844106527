import React, { useEffect } from "react";
import BasePage from "../components/basePage";
import PageName from "../components/PageName";

const About = () => {

    useEffect(() => {
        document.querySelector('meta[name="og:description"]').setAttribute(
            'content',
            'Загальна інформація про сервіс | Хто ми є?'
        );
        document.querySelector('title[id="mainTitle"]').textContent =
            'Про сервіс | Онлайн-сервіс Забронюй!';
    }, []);

    return (
        <BasePage>
            <PageName name='Про сервіс'/>
            <article className="pageBlock aboutArticle">
                {/* <h3 style={{color: "rgb(0, 17, 250)"}}>У зв'язку з тим, що рф-ія продовжує агресію проти України і розв'язала повномасштабну війну проти нашої країни, ми не надаємо і не будемо надавати будь-яких послуг громадянам цього "утворення" (рф).</h3>
                <h3 style={{color: "rgb(255, 196, 0)"}}>Оскільки білорусь активно допомагає "утворенню" з обстрілами мирних міст і сел України, то і вона "іде слідом за руським кораблем!"</h3>
                <br />
                <h4><i>Усім цивілізованим людям - Ласкаво Просимо! ;)</i></h4>
                <br /> */}
                <p>Вітаємо! Ми - онлайн сервіс бронювання місць і керування водоймою "Забронюй!".</p>
                <br />
                <p>Ключовою ціллю нашого сервісу є надання громадянам України зручного механізму пошуку водойм і бронювання місць на них для відпочинку, риболовлі або водного спорту.</p>
                <br />
                <p>За допомогою нашого сервісу Ви можете вирушити на рибалку або просто на відпочинок на воді, маючи заздалегідь заброньоване місце. Процес бронювання зручний і швидкий, тож Ви не витрачаєте зайвих зусиль і часу.</p>
                <br />
                <h4><i>Переваги співпраці з нами:</i></h4>
                <div className="fieldbase benefits">
                    <div className="fielddata benefit">Наглядна інформація про водойму/місця/послуги</div>
                    <div className="fielddata benefit">Беремо на себе витрати по підтримці сайту</div>
                    <div className="fielddata benefit">Позбавляємо зайвих дзінків для роз'яснення основної інформації</div>
                    <div className="fielddata benefit">Гнучка система оплати послуг - від кількості резервів</div>
                    <div className="fielddata benefit">Зручність і спрощення резерва місць відвідувачем</div>
                    <div className="fielddata benefit">Легке налаштування картки і схеми водойми</div>
                    <div className="fielddata benefit">Сервіс робить більшу частину роботи з відвідувачем</div>
                </div>
                <br />
                <p>В подальшому ми плануємо додати ще багато нового цікавого функціоналу і user experience. Вам має сподобатись!</p>
                <p>А поки-що будемо вдячні Вашій підтримці нашого сервісу у вигляді відвідувань Вами та Вашими друзями/родичами/знайомими!</p>
                <br />
                <p>Сподіваємося, ми будемо Вам корисні! :)</p>
            </article>
            <article className="pageBlock aboutArticle">
                <header><h3>1. Типи користувачів</h3></header>
                <br />
                <p>Всі користувачі даного сервісу поділяються на такі типи:</p>
                <br />
                <p> - <b><i>Водойма</i></b> (власник/адміністратор водойми);</p>
                <p> - <b><i>Відвідувач</i></b> (рядовий користувач, потенційний відвідувач водойми);</p>
                <p> - <b><i>Адміністратор</i></b> сервісу;</p>
                <br />
                <p>Для <b><i>Водойм</i></b> надається функціонал з:</p>
                <br />
                <p> - налаштування презентаційної картки водойми;</p>
                <p> - конфігурації схеми водойми;</p>
                <p> - адміністрування водойми (керування виділеними місцями як на всій водоймі, так і на кожній з її складових ділянок);</p>
                <br />
                <p>Для <b><i>Відвідувача</i></b> надається функціонал з:</p>
                <br />
                <p> - перегляду всіх наявних водойм;</p>
                <p> - перегляду картки водойми з детальною інформацією: контактів, списку послуг, правил, та іншого;</p>
                <p> - резервування місць для відпочинку на бажаній водоймі, яка є в переліку наявних у нашому сервісі;</p>
            </article>
            <article className="pageBlock aboutArticle">
                <header><h3>2. Типи водойм</h3></header>
                <br />
                <p>Цей ресурс має в собі два типи водойм:</p>
                <br />
                <p> - <b><i>Дика</i></b>: водойми загального користування (із вільним доступом відвідувачів);</p>
                <p> - <b><i>Приватна</i></b>: водойми, на яких доступ надається згідно правил і умов підприємства, що проводить на водоймі господарську діяльність відповідно до Законів України;</p>
                <br />
                <p>Основні клієнти нашого сервісу – це <b><i>приватні</i></b> водойми.</p>
                <p>Всі <b><i>приватні</i></b> водойми на нашому сайті – це користувачі з типом <b><i>Водойма</i></b>, тобто окремі юридичні або фізичні особи, які проводять на відповідних водоймах господарску діяльність згідно з Законами України, а також надають послуги з відпочинку, рибальства, риболовного спорту, або інших видів активного відпочинку на воді.</p>
                <p>Для <b><i>приватних</i></b> водойм послуги і функціонал нашого сервісу надається на комерційній, тобто платній, основі. Але ціна за користування функціоналом сайту доволі невисока – це всього 1% від суми кожного замовлення (бронювання місця) з використанням цього функціоналу.</p>
                <br />
                <p>Чому саме відсоток?</p>
                <p> - Ми намагаємося бути гнучкими, тож прагнемо надавати зручний, стабільний, і корисний функціонал, при цьому не обтяжуючи цільовий бізнес великими витратами.</p>
                <br />
                <p>Якщо, з якихось причин, на Вашій водоймі немає можливості забезпечити високий рівень відвідування, ми спробуємо допомогти Вам це виправити! Але і зайвих грошей ми не візьмемо.</p>
                <p><b><i>Менше замовлень – менша вартість</i></b>.</p>
                <p>Наша ціль – це залучити до Вас більше потенційно постійних відвідувачів.</p>
                <p><b><i>Чим більше заробляєте Ви, тим більше заробляємо ми!</i></b></p>
                <p>Але і в цьому випадку – математично пропорційно, нічого зайвого.</p>
                <br />
                <p>Якщо у Вас на водоймі все добре і завжди повно відвідувачів – хіба це не щастя?</p>
                <p>Якщо цей успіх буде досягнено, хоча й частково, завдяки саме нашому сервісу, то це вже буде найбільше щастя для нас!</p>
                <br />
                <p>До речі, перший місяць (30 днів) користування сервісом є <b>безкоштовним!</b></p>
                <p>Перш ніж платити - спробуйте і переконайтеся, що це саме те, що Вам потрібно.</p>
                <p>Приєднуйтесь! З нами просто, зрозуміло, і зручно.</p>
                <br />
                <p><b><i>Дикі</i></b> ж водойми, які є в переліку водойм нашого сервісу - це водойми виключно з вільним (безкоштовним) доступом до них громадян. Такі водойми повинні мати хоча б адміністратора-волонтера від офіційно оформленого (згідно Законів України) громадського об'єднання, який відповідатиме за порядок і чистоту на водоймі. Тобто цей тип водойм на нашому сервісі призначений для волонтерів, які зголосилися дбати про окремі водойми на добровільних засадах задля збереження і охорони водних ресурсів України.</p>
                <p>Функціонал сервісу щодо налаштування і керування водоймою для такої категорії громадян (волонтерів) надається абсолютно <b>безкоштовно</b>! Доступ до функціоналу (підтвердження аккаунту) в цьому випадку надається тільки після відповідної перевірки <b><i>Адміністратором</i></b> сервісу необхідних документів, а також після особистої бесіди з головою/представником відповідного волонтерського об'єднання.</p>
            </article>
            <article className="pageBlock aboutArticle">
                <header><h3>3. Реєстрація користувача</h3></header>
                <br />
                <p>На даному сервісі обов'язкова реєстрація проводиться для <u>власників/адміністраторів водойм</u>, тобто для користувачів типу <b><i>Водойма</i></b>. Комерційний функціонал сайту доступний користувачам типу <b><i>Водойма</i></b> тільки після реєстрації.</p>
                <p>Для користувачів сайту, які є відвідувачами водойм (<b><i>Відвідувач</i></b>), реєстрація на сайті, на даному етапі, не передбачена. Функціонал перегляду інформації про водойми а також бронювання місць на доступних водоймах надається таким користувачам на вільній основі.</p>
            </article>
        </BasePage>
    );
};

export default About;