import React from "react";
import Burger from "./Burger";
import Footer from "./Footer";
import Header from "./Header";

const BasePage = ({children}) => {
    return (
        <div>
            <Header />
            <Burger />
                {children}
            <Footer />
        </div>
    );
};

export default BasePage;