import React from 'react';
import { ABOUT_ROUTE, ACTIVATE_ROUTE, ADMIN_ROUTE, BOILIES_ROUTE, CONFIDETIAL_ROUTE, LAKEADMIN_ROUTE, LAKECONFIG_ROUTE, LAKEINFO_ROUTE, LAKELIST_ROUTE, LAKEPAGE_ROUTE, LOGIN_ROUTE, REGISTER_ROUTE } from './env';
import About from './pages/About';
import Confidential from './pages/Confidential';
import LakeAdmin from './pages/FUALakeadmin';
import LakeConfig from './pages/FUALakeconfig';
import LakeInfo from './pages/FUALakeinfo';
import LakeList from './pages/FUALakelist';
import LakePage from './pages/FUALakepage';
import Login from './pages/FUALogin';
import Register from './pages/FUARegister';
import Admin from './pages/admin';
import Activate from './pages/activate';
import Boilies from './components/Boilies';

export const authRoutes = [
    {
        path: ADMIN_ROUTE,
        component: <Admin />,
    },
    {
        path: ACTIVATE_ROUTE,
        component: <Activate />,
    },
    {
        path: LAKEADMIN_ROUTE,
        component: <LakeAdmin />,
    },
    {
        path: LAKECONFIG_ROUTE,
        component: <LakeConfig />,
    },
    {
        path: LAKEINFO_ROUTE,
        component: <LakeInfo />,
    },
];

export const pubRoutes = [
    {
        path: LOGIN_ROUTE,
        component: <Login />,
    },
    {
        path: REGISTER_ROUTE,
        component: <Register />,
    },
    {
        path: LAKELIST_ROUTE,
        component: <LakeList />,
    },
    {
        path: LAKEPAGE_ROUTE,
        component: <LakePage />,
    },
    {
        path: ABOUT_ROUTE,
        component: <About />,
    },
    {
        path: CONFIDETIAL_ROUTE,
        component: <Confidential />,
    },
    {
        path: BOILIES_ROUTE,
        component: <Boilies />,
    },
];
