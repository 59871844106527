import cl_Address from "../components/Address";

export default class cl_LakeInfo {

    constructor(user, Name) {
        if(user) this.User = user;
        this.addLake = Name ? false : true;
        this.Type = 'Приватна';
        this.Owner = false;

        this.JSON =
        this.st_Fishes =
        this.st_CatchStyles =
        this.Name =
        this.Location =
        this.StartDate =
        this.OwnerPhone =
        this.Info =
        this.Login =
        this.Pass =
        this.Approved =
        this.NewPass = '';
        this.Contacts =
        this.ar_Photo =
        this.ar_FishTypes =
        this.ar_CatchStyles =
        this.ar_Rules =
        this.ar_Services = [];

        this.Address = new cl_Address();
    }

    async transmit() {
        let frame = {
            "Type": this.Type,
            "lakeName": this.Name,
            "Address": this.Address.getObj(),
            "Coordinates": this.Location,
            "rentStartDate": this.StartDate,
            "ownerTel": this.OwnerPhone,
            "Info": this.Info,
            "Login": this.Login,
            "Pass": this.Pass,
            "NewPass": this.NewPass,
            "contacts": this.Contacts,
            "photos": this.ar_Photo,
            "fishTypes": this.ar_FishTypes,
            "catchStyles": this.ar_CatchStyles,
            "rules": this.ar_Rules,
            "services": this.ar_Services
        };
        let regFrame = {
            "lakeName": this.Name,
            "Address": this.Address.getObj(),
            "Coordinates": this.Location,
            "ownerTel": this.OwnerPhone,
            "Info": this.Info,
            "Login": this.Login,
            "Pass": this.Pass,
            "Promo": '',
        };
        let response;
        if(this.User.user.lakeName === 'Supercgenybr' && this.addLake) {
            response = await this.User.send(
                'cl_SuperAdmin',
                '/api/addLake',
                regFrame
            );
            if(response.Status !== 'Success') return response;
        }
        response = await this.User.send('cl_LakeInfo', '/api/updLakeData', frame);
        return response;
    }

    async receive(lakeName = '') {
        let response;
        if (this.User.user.lakeName === 'Supercgenybr') {
            if (lakeName !== '') {
                response = await this.User.requestPrereq(`api/getLakeInfo?name=${lakeName}`, true);
            } else {
                this.JSON = {};
                this.st_Fishes = await this.User.requestPrereq('fishtypes.json', true);
                this.st_CatchStyles = await this.User.requestPrereq('catchstyles.json', true);
                if (!this.st_Fishes || !this.st_CatchStyles)
                    return { Status: 'PREREQUISITES_ERROR' };
                return { Status: 'FAILED' };
            }
        } else {
            response = await this.User.requestPrereq('api/getLakeInfo', true);
        }
        this.JSON = response.Data;
        this.st_Fishes = await this.User.requestPrereq('fishtypes.json', true);
        this.st_CatchStyles = await this.User.requestPrereq('catchstyles.json', true);
        if (response.Status !== 'Success' || !this.st_Fishes || !this.st_CatchStyles)
            return { Status: 'PREREQUISITES_ERROR' };

        this.Type = this.JSON.Type;
        this.Owner = this.JSON.Owner;
        this.Name = this.JSON.lakeName;
        this.Address.fromJSON(this.JSON.Address);
        this.Location = this.JSON.Coordinates;
        this.StartDate = this.JSON.rentStartDate;
        this.OwnerPhone = this.JSON.ownerTel;
        this.Contacts = this.JSON.contacts;
        this.Info = this.JSON.Info;
        this.Login = this.JSON.ID;

        this.ar_Photo = this.JSON.photos;
        this.ar_FishTypes = this.JSON.fishTypes;
        this.ar_CatchStyles = this.JSON.catchStyles;
        this.ar_Rules = this.JSON.rules;
        this.ar_Services = this.JSON.services;
        this.Approved = this.JSON.Approved;
        return {Status:'Success'};
    }
}
