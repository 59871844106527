import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Context } from '../index';
import { authRoutes, pubRoutes } from '../routes';
import '../pages/FUAstyles.css';
import NoAccess from '../pages/noaccess';

const AppRouter = observer( () => {
    const {user} = useContext(Context);
    return (
        <Routes>
            {user.isAuth && authRoutes.map(({path, component}) =>
                <Route key={path} path={path} element={component} />
            )}
            {pubRoutes.map(({path, component}) =>
                <Route key={path} path={path} element={component} />
            )}
            <Route key='noaccess' path='*' element={NoAccess()} />
        </Routes>
    );
});

export default AppRouter;
