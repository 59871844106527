import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "..";
import { ABOUT_ROUTE, CONFIDETIAL_ROUTE, LAKELIST_ROUTE, LOGIN_ROUTE, REGISTER_ROUTE } from "../env";

const Footer = () => {
    const {user} = useContext(Context);

    return (
        <footer className="pageBlock footer" id="footer">
            <div className="horizontalBlock">
                <div className="footerContacts">
                    <p className="footerContact">
                        Підтримати проект: <a className="coffeeIcon" target="_blank" rel="noopener noreferrer" href="https://www.buymeacoffee.com/zabronuj">
                            <svg xmlns="http://www.w3.org/2000/svg" height="26" width="26" viewBox="0 0 512 512">{'<!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->'}
                                <path fill="#f97c15" d="M88 0C74.7 0 64 10.7 64 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C120.5 112.3 128 119.9 128 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C119.5 47.7 112 40.1 112 24c0-13.3-10.7-24-24-24zM32 192c-17.7 0-32 14.3-32 32V416c0 53 43 96 96 96H288c53 0 96-43 96-96h16c61.9 0 112-50.1 112-112s-50.1-112-112-112H352 32zm352 64h16c26.5 0 48 21.5 48 48s-21.5 48-48 48H384V256zM224 24c0-13.3-10.7-24-24-24s-24 10.7-24 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C232.5 112.3 240 119.9 240 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C231.5 47.7 224 40.1 224 24z" />
                            </svg>
                        </a>
                    </p>
                    <div className="footerContactGroup">
                        <a target="_blank" rel="noopener noreferrer" href="mailto:info@zabronuj.com.ua" className="footerContact">
                            <svg xmlns="http://www.w3.org/2000/svg" height="31" width="31" viewBox="0 0 512 512">{'<!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->'}
                                <path fill="#FFD43B" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
                            </svg>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="tel:+380984847574" className="footerContact">
                            <svg xmlns="http://www.w3.org/2000/svg" height="31" width="31" viewBox="0 0 512 512">{'<!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->'}
                                <path fill="#ff7070" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
                            </svg>
                        </a>
                    </div>
                    <div className="footerContactGroup">
                        <a target="_blank" rel="noopener noreferrer" href="https://t.me/zabronuj" className="footerContact">
                            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 496 512">{'<!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->'}
                                <path fill="#74C0FC" d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z" />
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="footerPages">
                    <Link className="footerPage" to={ABOUT_ROUTE}>Про Сервіс</Link>
                    <Link className="footerPage" to={CONFIDETIAL_ROUTE}>Політика конфіденційності</Link>
                    {!user.isAuth &&
                    <>
                        <Link className="footerPage" to={LAKELIST_ROUTE}>Каталог водойм</Link>
                        <Link className="footerPage" to={REGISTER_ROUTE}>Реєстрація</Link>
                        <Link className="footerPage" to={LOGIN_ROUTE}>Вхід</Link>
                    </>
                    }
                </div>
            </div>
            <div className="footerPartners">
                {/* <div className="fielddata footerPartner">Partner1</div>
                <div className="fielddata footerPartner">Partner2</div>
                <div className="fielddata footerPartner">Partner3</div> */}
            </div>
            <p style={{fontStyle: "italic"}}>Copyright © 2023-2024 Kolesnyk O.S.</p>
        </footer>
    );
};

export default Footer;