import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Context } from './index';
import AppRouter from './components/AppRouter';

const App = observer( () => {
    const {user} = useContext(Context);
    const [res, setRes] = useState(null);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setRes(await user.checkAuth());
    };

    return (
        <BrowserRouter>
            {res &&
                <AppRouter />
            }
        </BrowserRouter>
    );
});

export default App;
