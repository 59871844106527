import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Context } from '..';
import cl_Address from "../components/Address";
import BasePage from '../components/basePage';
import PageName from '../components/PageName';
import { ADDR_COUNTRY, LAKE_CARD } from '../env';

const LakeList = observer( () => {
    const {user} = useContext(Context);
    const [lakes, setLakes] = useState([]);
    const [area, setArea] = useState('');
    const [subArea, setSubArea] = useState('');
    const [lakeType, setLakeType] = useState('');
    const [catchStyle, setCatchStyle] = useState('');
    const [addrObj, setAddrObj] = useState([]);
    const [ar_lakeTypes, setLakeTypes] = useState([]);
    const [ar_catchStyles, setCatchStyles] = useState([]);
    const navigate = useNavigate();

    const Address = new cl_Address();

    const setLakeBackgrnd = (image) => {
        if(!image)
            image = 'test_picture1.jpg';
        return `${process.env.REACT_APP_API_URL}/${image}`;
    };

    useEffect(() => {
        document.querySelector('meta[name="og:description"]').setAttribute(
            'content',
            'Каталог водойм | Обрати водойму для відпочинку'
        );
        document.querySelector('title[id="mainTitle"]').textContent =
            'Каталог водойм | Онлайн-сервіс Забронюй!';
        init();
    }, []);

    const init = async () => {
        if(user.isAuth) {
            navigate(`${LAKE_CARD}${user.user.lakeName}`, { replace: false });
            return;
        }
        let Data = await user.requestPrereq('api/getLakeList');
        let lakeTypes = await user.requestPrereq('laketypes.json', true);
        let catchStyles = await user.requestPrereq('catchstyles.json', true);
        let response = await Address.init(user);
        if(!Data || !lakeTypes || !catchStyles || (response.Status !== 'Success'))
            return {Status:'PREREQUISITES_ERROR'};
        setAddrObj(Address.addrData);
        setLakes(Data);
        setLakeTypes(lakeTypes);
        setCatchStyles(catchStyles);
    };

    return (
        <BasePage>
            <PageName name='Каталог водойм'/>
            <section id="filter_section">
                <fieldset id="filter" className="lakefilter">
                    <div className="fieldbase">
                        <h3 className="filterheader">Фільтр</h3>
                    </div>
                    <div className="fieldbase flexRow">
                    <div className="fieldbase subInfo">
                        <label className="fieldname" htmlFor="state">{'Область:'}</label>
                        <select className="fielddata select" name="area" required
                            value={area} onChange={e => {
                                setArea(e.target.value);
                            }}
                        >
                            <option className="item" value="">{'< Всі >'}</option>
                            {addrObj && addrObj.map(item => {
                                if(item.Name === ADDR_COUNTRY) {
                                    return item.areas.map(area =>
                                        <option key={area.Name} className="item" value={area.Name}>
                                            {area.Name}
                                        </option>
                                    )
                                }
                                return null;
                            })}
                        </select>
                        <label className="fieldname" htmlFor="area">Район:</label>
                        <select className="fielddata select" name="subArea" required
                            value={subArea} onChange={e => {
                                setSubArea(e.target.value);
                            }}
                        >
                            <option className="item" value="">{'< Всі >'}</option>
                            {addrObj && addrObj.map(item => {
                                if(item.Name === ADDR_COUNTRY) {
                                    return item.areas.map(iarea => {
                                        if(iarea.Name === area) {
                                            return iarea.subAreas.map(sarea =>
                                                <option key={sarea.Name} className="item" value={sarea.Name}>
                                                    {sarea.Name}
                                                </option>
                                            )
                                        }
                                        return null;
                                    })
                                }
                                return null;
                            })}
                        </select>
                    </div>
                    <div className="fieldbase subInfo">
                        <label className="fieldname" htmlFor="type">Тип:</label>
                        <select className="fielddata select" name="type" required
                            value={lakeType} onChange={e => {
                                setLakeType(e.target.value);
                            }}
                        >
                            <option className="item" value="">{'< Всі >'}</option>
                            {ar_lakeTypes && ar_lakeTypes.map(item => 
                                <option key={item} className="item" value={item}>
                                    {item}
                                </option>
                            )}
                        </select>
                        <label className="fieldname" htmlFor="kind">Вид ловлі:</label>
                        <select className="fielddata select" name="catchStyles" required
                            value={catchStyle} onChange={e => {
                                setCatchStyle(e.target.value);
                            }}
                        >
                            <option className="item" value="">{'< Всі >'}</option>
                            {ar_catchStyles && ar_catchStyles.map(item => 
                                <option key={item} className="item" value={item}>
                                    {item}
                                </option>
                            )}
                        </select>
                    </div>
                    </div>
                </fieldset>
            </section>

            <section id="list">
                {lakes && (lakes instanceof Array) && lakes.map(item => {
                    if(area && item.Address.Area !== area) return null;
                    if(subArea && item.Address.SubArea !== subArea) return null;
                    if(lakeType && item.Type !== lakeType) return null;
                    if(catchStyle) {
                        let found = false;
                        item.catchStyles.forEach(istyle => {
                            if(istyle === catchStyle) found = true;
                        });
                        if(!found) return null;
                    }
                    return (
                        <article key={item.lakeName} className="lake" id={item.lakeName}>
                            <Link to={`lakepage?name=${item.lakeName}`}
                                style={{backgroundImage: `url(${setLakeBackgrnd(item.photos[0])})`}}
                            >
                                <legend className={item.Type === "Дика" ? "free" : "private"} id="laketype">{item.Type}</legend>
                                <h3>{item.lakeName}</h3>
                                <p id="address">{Address.fromJSON(item.Address) && Address.toString()}</p>
                            </Link>
                        </article>
                    );
                })}
            </section>
        </BasePage>
    );
});

export default LakeList;
