import React from 'react';
import BasePage from '../components/basePage';
import { LOGIN_ROUTE, REGISTER_ROUTE } from '../env';

const NoAccess = () => {
    return (
        <BasePage>
            <h1>Будь ласка, <a href={REGISTER_ROUTE}>зареєструйтеся</a> або <a href={LOGIN_ROUTE}>авторизуйтеся</a>!</h1>
        </BasePage>
    );
};

export default NoAccess;
