export const ADMIN_ROUTE = '/admin';
export const LOGIN_ROUTE = '/login';
export const LOGOUT_ROUTE = '/logout';
export const REGISTER_ROUTE = '/registration';
export const LAKEADMIN_ROUTE = '/lakeAdmin';
export const LAKECONFIG_ROUTE = '/lakeConfig';
export const LAKEINFO_ROUTE = '/lakeInfo';
export const LAKELIST_ROUTE = '/';
export const LAKEPAGE_ROUTE = '/lakePage';
export const ABOUT_ROUTE = '/about';
export const CONFIDETIAL_ROUTE = '/conf';
export const LAKE_CARD = '/lakepage?name=';
export const ACTIVATE_ROUTE = '/activate';
export const BOILIES_ROUTE = '/boilies';

export const ADDR_COUNTRY = 'Україна';
