import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import BasePage from '../components/basePage';
import { LakeScheme } from '../components/LakeScheme';
import PageName from '../components/PageName';

const LakeAdmin = observer( () => {

    useEffect(() => {
        document.querySelector('meta[name="og:description"]').setAttribute(
            'content',
            'Адміністрування водойми'
        );
        document.querySelector('title[id="mainTitle"]').textContent =
            'Адміністрування | Онлайн-сервіс Забронюй!';
    }, [])

    return (
        <BasePage>
            <PageName name='Адміністрування водойми'/>
            <LakeScheme type='admin' />
        </BasePage>
    );
});

export default LakeAdmin;
