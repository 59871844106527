import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Context } from '..';
import cl_Address from '../components/Address';
import BasePage from '../components/basePage';
import { LakeScheme } from '../components/LakeScheme';
import Services from '../components/Services';
import { getQueryParam } from '../components/common';

const LakePage = observer( () => {
    const {user} = useContext(Context);
    const [backGrnd, setBackGrnd] = useState('');
    const [type, setType] = useState('');
    const [lakeName, setLakeName] = useState('');
    const [address, setAddress] = useState('');
    const [coordinates, setCoordinates] = useState('');
    const [contacts, setContacts] = useState([]);
    const [fishTypes, setFishTypes] = useState([]);
    const [catchStyles, setCatchStyles] = useState([]);
    const [rules, setRules] = useState([]);
    const [info, setInfo] = useState('');
    const [services, setServices] = useState([]);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if(searchParams.has('name')) {
            let name = getQueryParam('name');
            document.querySelector('meta[name="og:description"]').setAttribute(
                'content',
                `Риболовля і відпочинок | ${name} | Забронювати місце`
            );
            document.querySelector('title[id="mainTitle"]').textContent =
                `${name} | Онлайн-сервіс Забронюй!`;
        }
        init();
    }, []);

    const init = async () => {
        let Data = await user.requestPrereq(`api/getLakeInfoPUB${searchParams.has('name')?'?'+searchParams:''}`);
        if(!Data)
            return {Status:'PREREQUISITES_ERROR'};
        let Addr = new cl_Address();
        Addr.fromJSON(Data.Address);
        setAddress(Addr.toString());
        setCoordinates(Data.Coordinates);
        if(Data.photos[0])
            setBackGrnd(`url(${process.env.REACT_APP_API_URL}/${Data.photos[0]})`);
        else
            setBackGrnd(`url(${process.env.REACT_APP_API_URL}/test_picture1.jpg)`);
        setType(Data.Type);
        setLakeName(Data.lakeName);
        setContacts(Data.contacts);
        setContacts(Data.contacts);
        setFishTypes(Data.fishTypes);
        setCatchStyles(Data.catchStyles);
        setRules(Data.rules);
        setInfo(Data.Info);
        setServices(Data.services);
    };

    return (
        <BasePage>
            <article className="pageBlock lakeform" style={{
                backgroundImage: backGrnd,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}>
                <header>
                    <legend className={type === "Дика" ? "free" : "private"} id='laketype'>{type}</legend>
                    <h2>{lakeName}</h2>
                    <div className='fieldbase'>
                        <p id='address'>{address}</p>
                    </div>
                    <div className='fieldbase'>
                        <p id='coordinates'>{coordinates}</p>
                    </div>
                </header>
                <legend className='fieldname'>{'Контакти:'}</legend>
                <div className='fieldbase staticList'>
                    {contacts.map(item =>
                        <p key={item} className='fielddata listItem'><a href={`tel:${item}`}>{item}</a></p>
                    )}
                </div>
                <legend className='fieldname'>{'Види риб:'}</legend>
                <div className='fieldbase staticList'>
                    {fishTypes.map(item =>
                        <p key={item} className='fielddata listItem'>{item}</p>
                    )}
                </div>
                <legend className='fieldname'>{'Види ловлі:'}</legend>
                <div className='fieldbase staticList'>
                    {catchStyles.map(item =>
                        <p key={item} className='fielddata listItem'>{item}</p>
                    )}
                </div>
                <legend className='fieldname'>{'Правила водойми:'}</legend>
                <div className='fieldbase staticList rulesList'>
                    {rules.map(item =>
                        <p key={item} className='fielddata listItem'>{item}</p>
                    )}
                </div>
                <div className='fieldbase'>
                    <Services type='info' obj={services} />
                </div>
                <legend className='fieldname'>{'Загальна інформація:'}</legend>
                <div className='fieldbase' style={{display: 'flex'}}>
                    <textarea className="fielddata infoArea" name="description" placeholder="< Загальний опис >" value={info}
                    style={{
                        padding: '7px',
                        scrollbarGutter: 'stable',
                        minHeight: '170px',
                        resize: 'vertical',
                        fontFamily: 'Arial, "MS Trebuchet", sans-serif',
                        fontSize: '100%',
                        fontStyle: 'italic',
                        color: 'black',
                        flex: '1 0 auto'
                    }} disabled />
                </div>
                {user.isAuth &&
                    <>
                    <legend className='fieldname'>{'Повне посилання на водойму:'}</legend>
                    <div className='fieldbase'>
                        <p className='fielddata' style={{padding: '7px'}}>
                            <a href={`${window.location.href}`}>
                                {`${window.location.origin}/lakepage?name=${user.user.lakeName}`}
                            </a>
                        </p>
                    </div>
                    </>
                }
            </article>
            {!user.isAuth &&
                <LakeScheme type='visitor' />
            }
        </BasePage>
    );
});

export default LakePage;
