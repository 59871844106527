import { observer } from "mobx-react-lite";
import React from "react";

const Popup = observer( ({children, open, name, onClick}) => {
    return (
        <div className={(open ? 'wrapperPopup open' : 'wrapperPopup') + ` ${name}`} onClick={(e) => {
            if(!e.target.closest(`.${name} .popup`)) {
                onClick(e);
            }
        }}>
            {children}
        </div>
    );
});

export default Popup;