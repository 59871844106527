import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "..";
import cl_Address from "./Address";
import Popup from "./Popup";

const AddressForm = observer( ({obj, onClick, onChange}) => {
    const {user} = useContext(Context);
    let Address = new cl_Address();
    const [addrObj, setAddrObj] = useState([]);
    const [country, setCountry] = useState('');
    const [area, setArea] = useState('');
    const [subarea, setSubArea] = useState('');
    const [cityType, setCityType] = useState('');
    const [city, setCity] = useState('');
    const [street, setStreet] = useState('');
    const [building, setBuilding] = useState('');

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        let response = await Address.init(user, obj);
        if(response.Status !== 'Success') return response;
        setAddrObj(Address.addrData);
        setCountry(obj.Country);
        setArea(obj.Area);
        setSubArea(obj.SubArea);
        setCityType(obj.LocType);
        setCity(obj.Locality);
        setStreet(obj.Street);
        setBuilding(obj.Building);
    };

    const apply = () => {
        Address.Country = country;
        Address.Area = area !== '< Область >' ? area : '';
        Address.SubArea = subarea !== '< Район >' ? subarea : '';
        Address.LocType = cityType !== '< Місто/Село/СМТ >' ? cityType : '';
        if(Address.LocType === '' || city === '')
            Address.LocType = Address.Locality = Address.Street = Address.Building = '';
        else {
            Address.Locality = city;
            if(street === '')
                Address.Street = Address.Building = '';
            else {
                Address.Street = street;
                Address.Building = building;
            }
        }
        onChange(Address.toString());
    };

    return (
        <Popup open={true} name="cl_Address" onClick={(e) => onClick(e)}>
            <div className="pageBlock formAddress mainInfo popup">
                <h3 className="header3">
                    Ввести адресу
                </h3>
                <select className="fielddata select inputText" name="country" value={country} required onChange={(e) => {
                    setCountry(e.target.value);
                }}>
                    <option className="item" value={"< Країна >"}>
                        {"< Країна >"}
                    </option>
                    {addrObj && addrObj.map(item =>
                        <option key={item.Name} className="item" value={item.Name}>
                            {item.Name}
                        </option>
                    )}
                </select>
                <select className="fielddata select inputText" name="area" value={area} required onChange={(e) => {
                    setArea(e.target.value);
                }}>
                    <option className="item" value={"< Область >"}>
                        {"< Область >"}
                    </option>
                    {addrObj && addrObj.map(item => {
                        if(item.Name === country) {
                            return item.areas.map(area =>
                                <option key={area.Name} className="item" value={area.Name}>
                                    {area.Name}
                                </option>
                            )
                        }
                        return null;
                    })}
                </select>
                <select className="fielddata select inputText" name="subArea" value={subarea} onChange={(e) => {
                    setSubArea(e.target.value);
                }}>
                    <option className="item" value={"< Район >"}>
                        {"< Район >"}
                    </option>
                    {addrObj && addrObj.map(item => {
                        if(item.Name === country) {
                            return item.areas.map(iarea => {
                                if(iarea.Name === area) {
                                    return iarea.subAreas.map(sarea =>
                                        <option key={sarea.Name} className="item" value={sarea.Name}>
                                            {sarea.Name}
                                        </option>
                                    )
                                }
                                return null;
                            })
                        }
                        return null;
                    })}
                </select>
                <select className="fielddata select inputText" name="citytype" value={cityType} required onChange={(e) => {
                    setCityType(e.target.value);
                }}>
                    <option className="item" value={"< Місто/Село/СМТ >"}>
                        {"< Місто/Село/СМТ >"}
                    </option>
                    {addrObj && addrObj.map(item => {
                        if(item.Name === country) {
                            return item.cityTypes.map(ctype =>
                                <option key={ctype} className="item" value={ctype}>
                                    {ctype}
                                </option>
                            )
                        }
                        return null;
                    })}
                </select>
                <input className="fielddata inputText" type="text" name="city" maxLength="30" placeholder="< Населений пункт >" required
                    value={city} onChange={e => {
                        setCity(e.target.value);
                }} />
                <input className="fielddata inputText" type="text" name="street" maxLength="30" placeholder="< Вулиця >"
                    value={street} onChange={e => {
                        setStreet(e.target.value);
                }} />
                <input className="fielddata inputText" type="text" name="building" maxLength="30" placeholder="< Номер будівлі >"
                    value={building} onChange={e => {
                        setBuilding(e.target.value);
                }} />
                <button className="button buttonSubmit" type="submit" onClick={(e) => {
                    e.preventDefault();
                    apply();
                    onClick();
                }}>Добавити / Змінити</button>
            </div>
        </Popup>
    );
});

export default AddressForm;