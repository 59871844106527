
function arrayFindItem(array, value) {
    if(!array || !value) {
        console.log("arrayFindItem failed!");
        return null;
    }

    for(let i = 0; i < array.length; i++) {
        if(array[i] === value) {
            return {"i":i, "obj":array[i]};
        }
    }
    return null;
}

function arrayFindItemSmart(array, value) {
    if(!array || !value) {
        console.log("arrayFindItemSmart failed!");
        return null;
    }

    for(let i = 0; i < array.length; i++) {
        if(sameTxt(array[i], value)) {
            return {"i":i, "obj":array[i]};
        }
    }
    return null;
}

function jsonFindItem(json, name) {
    if(!json || !name) {
        console.log("jsonFindItem failed!");
        return null;
    }

    for(let i = 0; i < json.length; i++) {
        if(json[i].Name === name) {
            return {"i":i, "obj":json[i]};
        }
    }
    return null;
}

function sameTxt(src, dst) {
    if(!src || !dst) {
        return null;
    }

    let newSrc, newDst, res;
    newSrc = src.split(" ").join("").replace(/\s/g,'');
    newDst = dst.split(" ").join("").replace(/\s/g,'');
    res = (newSrc.toUpperCase() === newDst.toUpperCase());
    if(res)
        return true;
    return false;
}

function find(array, sKey, sVal) {
    if(!array || !sKey || !sVal) return null;
    let found = null;
    array.forEach(item => {
        Object.keys(item).forEach(ikey => {
            if(ikey === sKey) {
                if(item[ikey] === sVal) {
                    found = item;
                }
            }
        });
    });
    return found;
}

function findService(array, name) {
    if(!array || !name) {
        console.log("findService failed!");
        return null;
    }

    for(let i = 0; i < array.length; i++) {
        if(sameTxt(array[i].Name, name)) {
            return array[i];
        }
    }
    return null;
}

function findServiceNDef(array, name) {
    if(!array || !name) {
        console.log("findServiceNDef failed!");
        return null;
    }

    for(let i = 0; i < array.length; i++) {
        if(array[i].Name === name && !array[i].Default) {
            return {"i": i, "obj": array[i]};
        }
    }
    return null;
}

function getQueryParam(name) {
    let res = '';
    let params = new URLSearchParams(window.location.search);
    if(params.has(name)) {
        res = params.get(name);
    }
    return res;
}

function addIDsToArray(array) {
    for(let i = 0; i < array.length; i++) {
        array[i]['id'] = i;
    }
}

function dayToDate(day) {
    let date = new Date();
    date.setDate(date.getDate() + day);
    return date;
}

function fixDate(srcDate) {
    if(!srcDate) return null;
    let date = new Date(srcDate);
    date = date.toISOString();
    date = date.split(':');
    date.splice(2, 1);
    date = date.join(':');
    return date;
}

function dateOffsetRemove(srcDate) {
    let now = new Date();
    if(srcDate) now = srcDate;
    let offset = now.getTimezoneOffset();
    now = now - ((offset - 5) * 60 * 1000);
    return fixDate(now);
}

export {
    arrayFindItem,
    arrayFindItemSmart,
    jsonFindItem,
    sameTxt,
    find,
    findService,
    findServiceNDef,
    getQueryParam,
    addIDsToArray,
    dayToDate,
    fixDate,
    dateOffsetRemove
};
