import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '..';
import AddressForm from '../components/AddressForm';
import BasePage from '../components/basePage';
import DynamicList from '../components/DynamicList';
import LocationForm from '../components/LocationForm';
import PageName from '../components/PageName';
import cl_LakeInfo from '../store/LakeInfoAPI';

const LakeInfoReg = observer( (props) => {
    const {user} = useContext(Context);
    const [addrPopup, setAddrPopup] = useState(false);
    const [locPopup, setLocPopup] = useState(false);
    const [commerse, setCommerse] = useState({});

    let lakeInfo = new cl_LakeInfo(user, props.Name);
    const [Type, setType] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [location, setLocation] = useState('');
    const [startDate, setStartDate] = useState('');
    const [ownerTel, setOwnerTel] = useState('');
    const [tel1, setTel1] = useState('');
    const [tel2, setTel2] = useState('');
    const [info, setInfo] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [passRep, setPassRep] = useState('');
    const [promo, setPromo] = useState('');
    const [ar_Photo, setPhoto] = useState([]);
    const [ar_FishTypes, setFishTypes] = useState([]);
    const [ar_CatchStyles, setCatchStyles] = useState([]);
    const [ar_Rules, setRules] = useState([]);
    const [ar_Services, setServices] = useState([]);
    const [approved, setApproved] = useState(true);

    const [object, setObject] = useState(lakeInfo);

    const newPassRef = useRef();
    const passRepRef = useRef();
    const loginRef = useRef();

    useEffect(() => {
        if (props.Name === '') fill();
        document.querySelector('meta[name="og:description"]').setAttribute(
            'content',
            'Дані водойми'
        );
        document.querySelector('title[id="mainTitle"]').textContent =
            'Дані | Онлайн-сервіс Забронюй!';
    }, []);

    useEffect(() => {
        if(user.user.lakeName !== 'Supercgenybr') {
            newPassRef.current.classList.remove('passEq');
            newPassRef.current.classList.remove('passNotEq');
            passRepRef.current.classList.remove('passEq');
            passRepRef.current.classList.remove('passNotEq');
            if(newPass || passRep) {
                if(newPass === passRep) {
                    newPassRef.current.classList.add('passEq');
                    passRepRef.current.classList.add('passEq');
                } else {
                    newPassRef.current.classList.add('passNotEq');
                    passRepRef.current.classList.add('passNotEq');
                }
            }
        }
    }, [newPass, passRep]);

    useEffect(() => {
        if (props.Name !== '') fill();
    }, [props.Name]);

    const fill = async () => {
        let response = await lakeInfo.receive(props.Name);
        if(response.Status !== 'Success') {
            setObject({...object, st_Fishes:lakeInfo.st_Fishes});
            switch(response.Status) {
                case 'PREREQUISITES_ERROR':
                case 'DB_ENTRY_NOT_FOUND':
                    await props.onClick();
                    break;
            }
            return false;
        }
        let comm = [];
        if (lakeInfo.Type == 'Приватна' && lakeInfo.Owner) {
            comm = await user.requestPrereq(`api/getCommerse${user.user.lakeName === 'Supercgenybr' ?
                '?name=' + props.Name
                : ''
            }`);
            setCommerse(comm);
        }
        if (user.user.lakeName === 'Supercgenybr') {
            if (comm.invoices && comm.invoices.length)
                props.onCommerse(lakeInfo.Login, comm.invoices);
            else
                props.onCommerse(null, []);
            if (!lakeInfo.Owner)
                loginRef.current.required = false;
            loginRef.current.disabled = false;
        }
        setType(lakeInfo.Type);
        setName(lakeInfo.Name);
        setAddress(lakeInfo.Address.toString());
        setLocation(lakeInfo.Location);
        setStartDate(lakeInfo.StartDate);
        setOwnerTel(lakeInfo.OwnerPhone);
        setTel1(lakeInfo.Contacts[0]);
        setTel2(lakeInfo.Contacts[1]);
        setInfo(lakeInfo.Info);
        setEmail(lakeInfo.Login);
        setPhoto(lakeInfo.ar_Photo);
        setFishTypes(lakeInfo.ar_FishTypes);
        setCatchStyles(lakeInfo.ar_CatchStyles);
        setRules(lakeInfo.ar_Rules);
        setServices(lakeInfo.ar_Services);
        setApproved(lakeInfo.Approved);
        setObject(lakeInfo);
    };

    const el_click = (e) => {
        if(e.target.matches('.addressInfo > .contextButton')) {
            e.preventDefault();
            setAddrPopup(true);
        } else if(e.target.matches('.lakeLocation > .contextButton')) {
            e.preventDefault();
            setLocPopup(true);
        }
    };

    const test = async (e) => {
        let response = null;
        for(let i = 0; i < 100; i++) {
            if (i !== 0) {
                response = await user.login(`domain${i}@zabronuj.com.ua`, `${i}`);
                if (response.Status !== 'Success') console.log('!Login error! -', response);
            }
            lakeInfo.Name = `LakeName${i}`;
            lakeInfo.Address.fromString(`Country, Area${i} обл., SubArea${i} р-н., село Locality${i}, вул. Street${i}, ${i}`);
            lakeInfo.Location = `45.${i}, 29.${i}`;
            lakeInfo.StartDate = '';
            lakeInfo.OwnerPhone = `0671112${i}`;
            lakeInfo.Contacts = [];
            lakeInfo.Contacts.push(`06711122${i}`);
            lakeInfo.Contacts.push(`06722233${i}`);
            lakeInfo.Info = `Test info text ${i}`;
            lakeInfo.Login = `domain${i}@zabronuj.com.ua`;
            lakeInfo.ar_Photo = lakeInfo.ar_FishTypes = lakeInfo.ar_CatchStyles = [];
            lakeInfo.ar_FishTypes = ["Сазан","Карась","Білий Амур","Товстолоб","Лінь","Плітка"];
            lakeInfo.ar_CatchStyles = ["Карпова","Поплавкова","Спінінгова"];
            lakeInfo.ar_Rules = [];
            for(let j = 0; j < 7; j++) {
                lakeInfo.ar_Rules.push(`Very Long Description Rule ${j}`);
            }
            lakeInfo.ar_Services = [];
            for(let k = 0; k < 7; k++) {
                lakeInfo.ar_Services.push({Name:`Long Service Name ${k}`, Price:(k+1), Quantity:1, quanType:'Разова', Default:false});
            }
            console.log('lakeInfo -', lakeInfo);
            response = await lakeInfo.transmit();
            if(response.Status !== 'Success') console.log('!error! -', response);
            await user.logout();
        }
        console.log('Test Done!');
    };

    const el_submit = async (e) => {
        e.preventDefault();
        if (newPass || passRep) {
            if (newPass === passRep) {
                lakeInfo.Pass = object.Pass;
                lakeInfo.NewPass = object.NewPass;
            } else {
                alert("Невірно заповнено паролі!");
                return null;
            }
        }
        if (user.user.lakeName === 'Supercgenybr')
            lakeInfo.Type = object.Type;
        lakeInfo.Name = object.Name;
        lakeInfo.Address.fromString(address);
        lakeInfo.Location = object.Location;
        lakeInfo.StartDate = object.StartDate;
        lakeInfo.OwnerPhone = object.OwnerPhone;
        if (tel1) lakeInfo.Contacts.push(tel1);
        if (tel2) lakeInfo.Contacts.push(tel2);
        lakeInfo.Info = object.Info;
        lakeInfo.Login = object.Login;
        if (user.user.lakeName === 'Supercgenybr') lakeInfo.Pass = object.Pass;
        lakeInfo.ar_Photo = object.ar_Photo;
        lakeInfo.ar_FishTypes = object.ar_FishTypes;
        lakeInfo.ar_CatchStyles = object.ar_CatchStyles;
        lakeInfo.ar_Rules = object.ar_Rules;
        lakeInfo.ar_Services = object.ar_Services;
        let response = await lakeInfo.transmit();
        if (response.Status !== 'Success') return false;
        if (user.user.lakeName !== 'Supercgenybr') {
            user.setUser(response.Data.user);
            localStorage.setItem('accessToken', response.Data.accessToken);
        } else if (props.Name === '')
            await props.onChange();
        alert("Дані збережено!");
        setPass('');
        setObject({ ...object, Pass: '' });
        setNewPass('');
        setObject({ ...object, NewPass: '' });
        setPassRep('');
    };

    return (
        <>
            {user.user.lakeName !== 'Supercgenybr' && <PageName name='Персональні дані водойми'/>}
            <form className="pageBlock formRegister lakeInfo" action="#" method="post"
                onClick={el_click} onSubmit={el_submit}
            >
                <legend className="fieldname">Загальні дані:</legend>
                <div className='halfBlockContainer'>
                    <div className="fieldbase mainInfo halfBlock">
                        {user.user.lakeName === 'Supercgenybr' &&
                            <select className="fielddata select" name="type" required value={Type} onChange={e => {
                                setType(e.target.value);
                                setObject({...object, Type:e.target.value});
                            }}>
                                <option className="item" value={'Приватна'}>
                                    {'Приватна'}
                                </option>
                                <option className="item" value={'Дика'}>
                                    {'Дика'}
                                </option>
                            </select>
                        }
                        <input className="fielddata inputText" type="text" name="Name" maxLength="30" placeholder="< Назва водойми >" required
                            value={name} onChange={e => {
                                setName(e.target.value);
                                setObject({...object, Name:e.target.value});
                            }} />
                        <div className="addressInfo fieldbase fielddata inputText advanceField lakeAddress">
                            <input className="inputText" type="text" name="Address" maxLength="30" placeholder="< Адреса водойми >" disabled required
                                defaultValue={address}/>
                            <button className="button contextButton" type="button">...</button>
                        </div>
                        <div className="fieldbase fielddata inputText advanceField lakeLocation">
                            <input className="inputText" type="text" name="Coordinates" maxLength="30" placeholder="< Координати >" disabled required
                                defaultValue={location}/>
                            <button className="button contextButton" type="button">...</button>
                        </div>
                        <input className="fielddata inputText" type="date" name="BirthDay" title="< Дата початку оренди >"
                            value={startDate} onChange={e => {
                                setStartDate(e.target.value);
                                setObject({...object, StartDate:e.target.value});
                            }}/>
                        <input className="fielddata inputText" type="tel" name="ownerTel" maxLength="13" placeholder="< Телефон власника >" required
                            value={ownerTel} onChange={e => {
                                setOwnerTel(e.target.value);
                                setObject({...object, OwnerPhone:e.target.value});
                            }}/>
                        <input className="fielddata inputText" type="tel" name="securityTel1" maxLength="13" placeholder="< Телефон охорони 1 >" required
                            value={tel1} onChange={e => {
                                setTel1(e.target.value);
                            }}/>
                        <input className="fielddata inputText" type="tel" name="securityTel2" maxLength="13" placeholder="< Телефон охорони 2 >"
                            value={tel2} onChange={e => {
                                setTel2(e.target.value);
                            }}/>
                        <textarea className="fielddata inputText textArea" name="description" placeholder="< Загальний опис >" rows="5" required
                            value={info} onChange={e => {
                                setInfo(e.target.value);
                                setObject({...object, Info:e.target.value});
                            }}/>
                        <input className="fielddata inputText" type="email" id="email" ref={loginRef} maxLength="100" placeholder="< Login / Email >" disabled required
                            value={email} onChange={e => {
                                setEmail(e.target.value);
                                setObject({...object, Login:e.target.value});
                            }}/>
                        <input className="fielddata inputText" type="password" id="oldPassword" maxLength="100" placeholder="< Old Password >" autoComplete="off"
                            value={pass} onChange={e => {
                                setPass(e.target.value);
                                setObject({ ...object, Pass: e.target.value });
                            }} />
                        {user.user.lakeName !== 'Supercgenybr' &&
                        <>
                            <input className="fielddata inputText" type="password" id="newPassword" ref={newPassRef} maxLength="100" placeholder="< New Password >" autoComplete="off"
                                value={newPass} onChange={e => {
                                    setNewPass(e.target.value);
                                    setObject({ ...object, NewPass: e.target.value });
                                }} />
                            <input className="fielddata inputText" type="password" id="passwordRep" ref={passRepRef} maxLength="100" placeholder="< New Password Again >" autoComplete="off"
                                value={passRep} onChange={e => {
                                    setPassRep(e.target.value);
                                }} />
                        </>
                        }
                    </div>
                    <div className="fieldbase commerseData halfBlock">
                        {object.JSON.Type === 'Приватна' && object.JSON.Owner &&
                        <>
                            <h2 className='header2'>Комерційні дані:</h2>
                            {approved ?
                                <div className='fielddata' style={{ backgroundColor: 'rgba(154, 205, 50, 0.8)' }}>
                                    <p>Акаунт активний</p>
                                </div>
                                :
                                <div className='fielddata' style={{ backgroundColor: 'rgba(240, 128, 128, 1)' }}>
                                    <p>Акаунт заблокований!</p>
                                </div>
                            }
                            <div className='fielddata totalSum'>
                                <div className='label'>Попередня сума:</div>
                                {commerse.totalSum} {object.JSON.Currency}
                            </div>
                            <div className='fielddata monthSum'>
                                <div className='label'>Поточна місячна сума:</div>
                                {commerse.monthSum} {object.JSON.Currency}
                            </div>
                            <div className='fielddata percent'>
                                <div className='label'>Тариф:</div>
                                {commerse.percent}%
                            </div>
                            <div className='fielddata requisites'>
                                <div className='title'>Реквізити для оплати послуг:</div>
                                <div className='requisiteField iban'>Рахунок IBAN: 
                                    <p className='fielddata'>UA263808050000000026003385742</p>
                                </div>
                                <div className='requisiteField'>Отримувач: 
                                    <p className='fielddata'>ФОП Колесник Олексій Сергійович</p>
                                </div>
                                <div className='requisiteField'>ІНН: 
                                    <p className='fielddata'>3220314019</p>
                                </div>
                                <div className='requisiteField paymentTarget'>Призначення: 
                                    <p className='fielddata'>Оплата послуг сервісу онлайн-бронювання</p>
                                </div>
                                <div className='requisiteField'>Сума до сплати:
                                    <div className='requisiteField'>
                                        <p className='fielddata'>{commerse.toPay}</p>
                                        <p className='fielddata'>{object.JSON.Currency}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='fielddata promo'>
                                <input className="fielddata inputText" type="text" name="promocode" maxLength="30" placeholder="< Промо-код >"
                                    value={promo} onChange={e => {
                                        setPromo(e.target.value);
                                    }} />
                                <button className='button' type="button">Задіяти</button>
                            </div>
                        </>
                        }
                    </div>
                </div>
                <hr className="delim" />
                <legend className="fieldname">Фото:</legend>
                <DynamicList name='lakePhoto' buttonName='photo newPhoto' obj={ar_Photo} onChange={(newArray) => {
                    setPhoto([...newArray]);
                    setObject({...object, ar_Photo:newArray});
                }}>
                    {ar_Photo}
                </DynamicList>
                <hr className="delim" />
                <div className="fieldbase formData">
                    <legend className="fieldname">Види риб:</legend>
                    <DynamicList name='lakeFishes' src={object.st_Fishes} obj={ar_FishTypes} onChange={(newArray) => {
                        setFishTypes([...newArray]);
                        setObject({...object, ar_FishTypes:newArray});
                    }}>
                        {ar_FishTypes}
                    </DynamicList>
                    <hr className="delim" />
                    <legend className="fieldname">Види ловлі:</legend>
                    <DynamicList name='lakeCatchTypes' src={object.st_CatchStyles} obj={ar_CatchStyles} onChange={(newArray) => {
                        setCatchStyles([...newArray]);
                        setObject({...object, ar_CatchStyles:newArray});
                    }}>
                        {ar_CatchStyles}
                    </DynamicList>
                    <hr className="delim" />
                    <legend className="fieldname">Правила водойми:</legend>
                    <DynamicList name='lakeRules' obj={ar_Rules} onChange={(newArray) => {
                        setRules([...newArray]);
                        setObject({...object, ar_Rules:newArray});
                    }}>
                        {ar_Rules}
                    </DynamicList>
                    <hr className="delim" />
                    <DynamicList name='services' type='config' obj={ar_Services} onChange={(newArray) => {
                        setServices([...newArray]);
                        setObject({...object, ar_Services:newArray});
                    }}>
                    </DynamicList>
                </div>
                <hr className="delim" />
                <button className="button buttonSubmit" type="submit">Змінити</button>
            </form>
            {addrPopup &&
                <AddressForm obj={lakeInfo.Address.fromString(address)} onClick={(e) => {
                    setAddrPopup(false);
                }}
                onChange={(string) => {
                    setAddress(string);
                }} />
            }
            {locPopup &&
                <LocationForm obj={object.Location} onClick={(e) => {
                    setLocPopup(false);
                }}
                onChange={(string) => {
                    setLocation(string);
                    setObject({...object, Location:string});
                }} />
            }
        </>
    );
});

const LakeInfo = observer( () => {
    return (
        <BasePage>
            <LakeInfoReg/>
        </BasePage>
    );
});

export default LakeInfo;
export { LakeInfoReg };
