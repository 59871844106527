import axios from "axios";

const $host = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL
});

const $authHost = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL
});

const authInterceptor = config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    return config;
};

$authHost.interceptors.request.use(authInterceptor);
// $authHost.interceptors.response.use(config => {
//     return config;
// }, async error => {
//     let originalRequest = error.config;
//     if(error.response.status === 401 && originalRequest && !originalRequest._isRetry) {
//         try {
//             originalRequest._isRetry = true;
//             console.log('Token invalid. Refreshing...');
//             const {data} = await $host.get('/api/refresh');
//             localStorage.setItem('accessToken', data.Data.accessToken);
//             originalRequest.headers.Authorization = `Bearer ${data.Data.accessToken}`;
//             console.log('Request retry...');
//             return originalRequest;//$authHost.request(originalRequest);
//         } catch (err) {
//             console.log('Fail! Not authorized!');
//         }
//     }
//     throw error;
// });

export {
    $host,
    $authHost
};
