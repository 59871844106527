import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Context } from "..";
import Popup from "./Popup";
import { findService } from "./common";

const UserInput = observer( ({src, obj, onChange, onClick}) => {
    const {user} = useContext(Context);
    const [files, setFiles] = useState([]);
    const [text, setText] = useState('');

    let type = obj.type;
    if(obj.class) type += " " + obj.class;

    const sendPhoto = async (file) => {
        let formData = new FormData();
        formData.append('img', file, file.name);
        let response = await user.sendFile(formData);
        return response;
    };

    return (
        <Popup open={true} name="cl_UserInput" onClick={(e) => onClick(e)}>
        <div className={`pageBlock ${type} popup`}>
            {type === 'inputPhoto' &&
                <>
                    <div className="fieldbase fielddata photoList">
                        {files && files.length > 0 && [...files].map(item =>
                            <p className = "fielddata photoItem">{item.name}</p>
                        )}
                    </div>
                    <div className="fieldbase">
                        <p className="fielddata fileSizeWarn">Розмір кожного файлу не має перевищувати 5 МБ!</p>
                    </div>
                    <input className="fielddata inputFile" type="file" id="file" name="file" multiple accept="image/*"
                        files={files} onChange={e => {
                            setFiles(e.target.files);
                        }}
                    />
                    <div className="fieldbase buttonsArea">
                        <button className="button buttonReset" onClick={e => {
                            e.preventDefault();
                            setFiles([]);
                        }}
                        >Очистити</button>
                        <button className="button buttonSubmit Apply" onClick={async (e) => {
                            e.preventDefault();
                            let array = [];
                            let tFiles = [...files];
                            if((tFiles.filter(value => value.size > 5000000)).length > 0) {
                                alert('Розмір одного з файлів більше 5 МБ! Будь ласка, оберіть файли розміром до 5 МБ!');
                                return null;
                            }
                            for(let i = 0; i < tFiles.length; i++) {
                                let response = await sendPhoto(tFiles[i]);
                                if(response.Status === 'Success') {
                                    array.push(response.Data);
                                }
                            }
                            onChange(array);
                            onClick(e);
                        }}
                        >Підтвердити</button>
                    </div>
                </>
            }
            {/* Object item */}
            {type === 'inputList' && (src[0] instanceof Object) &&
                <div className="fieldbase list" onClick={e => {
                    if(e.target.matches('.listItem')) {
                        e.preventDefault();
                        let newItem = findService(src, e.target.textContent);
                        onChange({...newItem});
                    }
                }}>
                    {src && src.map(item =>
                        <button key={item.Name} className="button fielddata listItem">
                            {item.Name}
                        </button>
                    )}
                </div>
            }
            {/* String item */}
            {type === 'inputList' && !(src[0] instanceof Object) &&
                <div className="fieldbase list" onClick={e => {
                    if(e.target.matches('.listItem')) {
                        e.preventDefault();
                        onChange(e.target.textContent);
                    }
                }}>
                    {(src && (src.length > 0))
                    ?   src.map(item =>
                            <button key={item} className="button fielddata listItem">
                                {item}
                            </button>
                        )
                    :   <p>Немає елементів</p>
                    }
                </div>
            }
            {type === 'inputUserText' &&
                <>
                    <div className="fieldbase text">
                        <textarea className="fielddata inputText textArea" name="Text" placeholder="< Текст >"
                            rows="5" required value={text} onChange={e => {
                                setText(e.target.value);
                            }}
                        />
                        <button className="button buttonSubmit Apply" onClick={e => {
                            e.preventDefault();
                            let newItem = text;
                            if(src) {
                                newItem = {...src};
                                newItem.Name = text;
                                if(!newItem.Name) newItem = null;
                            }
                            if(newItem) onChange(newItem);
                        }}
                        >Підтвердити</button>
                    </div>
                </>
            }
        </div>
        </Popup>
    );
});

export default UserInput;