import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ABOUT_ROUTE, ADMIN_ROUTE, BOILIES_ROUTE, LAKEADMIN_ROUTE, LAKECONFIG_ROUTE, LAKEINFO_ROUTE, LAKELIST_ROUTE, LAKE_CARD, LOGIN_ROUTE, LOGOUT_ROUTE, REGISTER_ROUTE } from '../env';
import { Context } from '../index';

const MyMenu = () => {
    const {user} = useContext(Context);
    const navigate = useNavigate();

    const SignOut = async (e) => {
        e.preventDefault();
        await user.logout();
        navigate(LAKELIST_ROUTE, { replace: false });
    };

    return (
        <nav className="menu">
            <ul>
                {user.user.lakeName !== 'Supercgenybr' &&
                <>
                    {user.isAuth ?
                        <li><Link className="menuItem" to={`${LAKE_CARD}${user.user.lakeName}`}>Картка водойми</Link></li>
                        :
                        <li><Link className="menuItem" to={LAKELIST_ROUTE}>Каталог водойм</Link></li>
                    }
                    <li><Link className="menuItem" to={ABOUT_ROUTE}>Про Сервіс</Link></li>
                    {user.isAuth &&
                        <li><Link className="menuItem" to={LAKEADMIN_ROUTE}>Адмін</Link></li>
                    }
                    {user.isAuth &&
                        <li><Link className="menuItem" to={LAKECONFIG_ROUTE}>Конфігурація</Link></li>
                    }
                    {user.isAuth &&
                        <li><Link className="menuItem" to={LAKEINFO_ROUTE}>Дані</Link></li>
                    }
                    {!user.isAuth &&
                        <li><Link className="menuItem" to={REGISTER_ROUTE}>Реєстрація</Link></li>
                    }
                </>
                }
                {user.user.lakeName === 'Supercgenybr' &&
                    <li><Link className="menuItem" to={ADMIN_ROUTE}>Адмін</Link></li>
                }
                {!user.isAuth &&
                    <li><Link className="menuItem" to={BOILIES_ROUTE}>Бойли</Link></li>
                }
                {user.isAuth ?
                    <li><Link className="menuItem" to={LOGOUT_ROUTE} onClick={SignOut}>Вихід</Link></li>
                    :
                    <li><Link className="menuItem" to={LOGIN_ROUTE}>Вхід</Link></li>
                }
            </ul>
        </nav>
    );
};

export default MyMenu;
