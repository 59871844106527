import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '..';
import BasePage from '../components/basePage';
import PageName from '../components/PageName';
import { ADMIN_ROUTE, LAKEADMIN_ROUTE } from '../env';

const Login = observer( () => {
    const {user} = useContext(Context);
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        document.querySelector('meta[name="og:description"]').setAttribute(
            'content',
            'Увійти в кабінет керування водоймою'
        );
        document.querySelector('title[id="mainTitle"]').textContent =
            'Вхід в аккаунт | Онлайн-сервіс Забронюй!';
    }, [])

    const signIn = async (e) => {
        e.preventDefault();
        let response = await user.login(email, pass);
        if (response.Status !== 'Success') return response;
        if(response.Data.user.lakeName === 'Supercgenybr')
            navigate(ADMIN_ROUTE, { replace: false });
        else
            navigate(LAKEADMIN_ROUTE, { replace: false });
    }

    const Test = async (e) => {
        e.preventDefault();
        let response = user.login(email, pass);
        console.log('response1 -', response);
        response = await user.login(email, pass);
        console.log('response2 -', response);
        if (response.Status !== 'Success') return response;
    }

    return (
        <BasePage>
            <PageName name='Вхід'/>
            <form className="login" action="#" method="post">
                <ol>
                    <legend>Вхід в аккаунт</legend>
                    <li>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="email" name="email"
                            value={email} onChange={e => setEmail(e.target.value)} required
                        />
                    </li>
                    <li>
                        <label htmlFor="password">Password</label>
                        <input id="password" type="password" name="password" autoComplete='off'
                            value={pass} onChange={e => setPass(e.target.value)} required
                        />
                    </li>
                    <li>
                        <button className="button" type="submit"
                            onClick={signIn}
                        >Вхід</button>
                    </li>
                </ol>
            </form>
        </BasePage>
    );
});

export default Login;
