import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '..';
import PageName from '../components/PageName';
import BasePage from '../components/basePage';
import { LakeInfoReg } from './FUALakeinfo';

const Admin = observer( (props) => {
    const {user} = useContext(Context);
    const [lakes, setLakes] = useState(0);
    const [owners, setOwners] = useState(0);
    const [summ, setSumm] = useState(0);
    const [toPay, setToPay] = useState(0);
    const [lakeName, setLakeName] = useState('');
    const [infoShow, setInfoShow] = useState(false);
    const [lakeID, setLakeID] = useState('');
    const [endDate, setEndDate] = useState('');
    const [invoices, setInvoices] = useState([]);
    const [invoice, setInvoice] = useState({});

    const nameRef = useRef();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let Data = await user.requestPrereq('api/getStats');
        if(!Data)
            return {Status:'PREREQUISITES_ERROR'};
        setLakes(Data.lakes);
        setOwners(Data.owners);
        setSumm(Data.totalSum);
        setToPay(Data.sumToPay);
    };

    const setPaid = async () => {
        let frame = {
            'ID': lakeID,
            'endDate': endDate,
            'paid': true
        }
        let response = await user.send(
            'cl_SuperAdmin',
            '/api/setPaid',
            frame
        );
        if(response.Status !== 'Success') return response;
    };

    return (
        <BasePage>
            <PageName name='СуперАдміністратор'/>
            <div className="pageBlock adminBlock adminStats">
                <div className='fielddata adminField'>Lakes: <b>{lakes}</b></div>
                <div className='fielddata adminField'>Owners: <b>{owners}</b></div>
                <div className='fielddata adminField'>MonthSum: <b>{summ}</b></div>
                <div className='fielddata adminField'>SumToPay: <b>{toPay}</b></div>
            </div>
            <div className="pageBlock adminBlock adminStats">
                <input className="fielddata inputText adminField" type="text" name="Name" ref={nameRef} maxLength="30" placeholder="< Водойма >" />
                <button className='button adminField' type="button" onClick={() => {
                    if (nameRef.current.value !== '') {
                        setLakeName(nameRef.current.value);
                        setInfoShow(true);
                    } else {
                        setInfoShow(false);
                        setLakeID('');
                        setInvoices([]);
                        setInvoice({});
                    }
                }}>Знайти</button>
                <button className='button buttonSubmit adminField' type="button" onClick={() => {
                    setLakeID('');
                    setInvoices([]);
                    setInvoice({});
                    setLakeName('');
                    nameRef.current.value = '';
                    setInfoShow(true);
                }}>Нова</button>
            </div>
            {(invoices && invoices.length > 0) &&
                <div className="pageBlock adminBlock adminStats">
                    <select className="fielddata select" name="subLake" onChange={e => {
                        let inv = invoices.find(value => value.endDate === e.target.value);
                        setInvoice(inv);
                        if (inv && inv.endDate)
                            setEndDate(inv.endDate);
                    }}>
                        <option className="item" value={"< Рахунок >"}>
                            {"< Рахунок >"}
                        </option>
                        {invoices && invoices.map(item =>
                            <option key={item.endDate} className="item" value={item.endDate}>
                                {item.endDate}
                            </option>
                        )}
                    </select>
                    {(invoice && invoice.endDate) &&
                    <>
                        <div className='fieldBase invData'>
                            <div className='fielddata adminField invDataItem'>startDate: <b>{invoice.startDate}</b></div>
                            <div className='fielddata adminField invDataItem'>endDate: <b>{invoice.endDate}</b></div>
                            <div className='fielddata adminField invDataItem'>monthSum: <b>{invoice.monthSum}</b></div>
                            <div className='fielddata adminField invDataItem'>toPay: <b>{invoice.toPay}</b></div>
                            <div className='fielddata adminField invDataItem' style={{
                                backgroundColor: invoice.payed ? 'rgba(154, 205, 50, 0.8)' : 'rgba(240, 128, 128, 1)'
                            }}>
                                payed: <b>{invoice.payed ? 'Yes' : 'No'}</b>
                            </div>
                            {invoice.payed ||
                                <button className='button setPaid adminField invDataItem sb_enable' type="button" onClick={async () => {
                                    await setPaid();
                                    await init();
                                }}>Сплачено!</button>
                            }
                        </div>
                    </>
                    }
                </div>
            }

            {infoShow &&
            <>
                <LakeInfoReg Name={lakeName} onChange={async () => {
                    await init();
                    setInfoShow(false);
                }} onClick={async () => {
                    setInvoices([]);
                    setInvoice({});
                    setInfoShow(false);
                }} onCommerse={(id, invArray) => {
                    setLakeID(id ? id : '');
                    setInvoices(invArray);
                    setInvoice({});
                }} />
            </>
            }
        </BasePage>
    );
});

export default Admin;
