import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Context } from "..";
import PageName from "../components/PageName";
import BasePage from "../components/basePage";
import { getQueryParam } from "../components/common";
import { LAKEINFO_ROUTE } from '../env';

const Activate = observer( () => {
    const {user} = useContext(Context);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() =>{
        document.querySelector('meta[name="og:description"]').setAttribute(
            'content',
            'Активація аккаунту | Підтвердити e-mail'
        );
        document.querySelector('title[id="mainTitle"]').textContent =
            'Активація аккаунту | Онлайн-сервіс Забронюй!';
        init();
    }, []);

    const init = async () => {
        if(searchParams.has('link')) {
            let response = await user.send('', '/api/activate', getQueryParam('link'));
            if(response.Status !== 'Success') return response;
            navigate(LAKEINFO_ROUTE, { replace: false });
        }
    };
    return (
        <BasePage>
            <PageName name='Активація аккаунту'/>
            <div className="pageBlock activateMessage">
                <div className="fieldbase">
                    <p>На Вашу електронну адресу <b>{user.user.email}</b> було відправлено лист активації.</p>
                </div>
                <div className="fieldbase">
                    <p>Будь ласка, перейдіть по посиланню, що міститься в листі!</p>
                </div>
            </div>
        </BasePage>
    );
});

export default Activate;