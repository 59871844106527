import React from "react";

const PageName = (props) => {
    return (
        <section id="pagename" className="pagename">
            <h3 className="header3">{props.name}</h3>
        </section>
    );
};

export default PageName;