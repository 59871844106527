import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "..";
import Popup from "./Popup";

const LocationForm = observer( ({obj, onClick, onChange}) => {
    const {user} = useContext(Context);
    const [types, setTypes] = useState([]);
    const [type, setType] = useState('текст');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        let gotTypes = await user.requestPrereq('locationtypes.json', true);
        if(!gotTypes)
            return {Status:'PREREQUISITES_ERROR'};
        setTypes(gotTypes);
        fromString(obj);
    };

    const apply = () => {
        let string = latitude + ", " + longitude;
        onChange(string);
    };

    const fromString = (string) => {
        if(string) {
            let parts = string.split(", ");
            setLatitude(parts[0]);
            setLongitude(parts[1]);
        }
    };

    return (
        <Popup open={true} name="cl_LocationForm" onClick={(e) => onClick(e)}>
            <div className="pageBlock formLocation mainInfo popup">
                <h3 className="header3">
                    Ввести координати
                </h3>
                <select className="fielddata select inputText" name="type" value={type} required onChange={(e) => {
                    setType(e.target.value);
                }}>
                    <option className="item" value={"< Тип >"}>
                        {"< Тип >"}
                    </option>
                    {types && types.map(item =>
                        <option key={item} className="item" value={item}>
                            {item}
                        </option>
                    )}
                </select>
                <input className="fielddata inputText" type="text" name="latitude" maxLength="12" placeholder="< Широта >"
                    value={latitude} onChange={e => {
                        setLatitude(e.target.value);
                }} />
                <input className="fielddata inputText" type="text" name="longitude" maxLength="12" placeholder="< Довгота >"
                    value={longitude} onChange={e => {
                        setLongitude(e.target.value);
                }} />
                <button className="button buttonSubmit" type="submit" onClick={(e) => {
                    e.preventDefault();
                    apply();
                    onClick();
                }}>Добавити / Змінити</button>
            </div>
        </Popup>
    );
});

export default LocationForm;