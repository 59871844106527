import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UserStore from './store/userStore';

const User = new UserStore();
export const Context = createContext({
    user: User,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Context.Provider value={{
            user: User,
        }}
    >
        <App />
    </Context.Provider>,
);
