import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import BasePage from '../components/basePage';
import { LakeSchemeConfig } from '../components/LakeScheme';
import PageName from '../components/PageName';

const LakeConfig = observer (() => {

    useEffect(() => {
        document.querySelector('meta[name="og:description"]').setAttribute(
            'content',
            'Конфігурація водойми'
        );
        document.querySelector('title[id="mainTitle"]').textContent =
            'Конфігурація | Онлайн-сервіс Забронюй!';
    }, [])

    return (
        <BasePage>
            <PageName name='Конфігурація водойми'/>
            <LakeSchemeConfig />
        </BasePage>
    );
});

export default LakeConfig;
